import React, { useEffect, useState } from 'react';
import { Button, useGetList, useListController } from "react-admin";
import moment from 'moment';
import {
  Box, Typography,
  Card
} from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import CarIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import PlayIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import FormLabel from "@mui/material/FormLabel";
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import '../views/trails/trail.css';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CardActions from '@mui/material/CardActions';
import vtsDataProvider from './../providers/dataProvider';
import TrailsRouting from './../components/TrailsRouting';
import { styled } from '@mui/material/styles';


// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import IconButton from '@mui/material/IconButton';


const errorstyle = {
  color: "red",
  fontSize: "12px",
};

const defaultValues = {
  from: "",
  to: "",
  deviceId: ""
}
const defaultValues1 = {
  filter: {
    from: "",
    to: "",
    deviceId: ""
  }
}

export const TrailsCardList = () => {
  const [devices, setDevices] = React.useState([]);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [expanded, setExpanded] = React.useState(false);
  const [TrailsRouteView, setTrailsRouteView] = React.useState(false);
  const [filter, setFilter] = React.useState(defaultValues1);
  const [trailsData, setTrailsData] = React.useState([]);
  const [playRoutesData, setPlayRoutesData] = React.useState([]);
  const [DeviceValue, setDeviceValue] = useState(null);
  const [ReportsRoute, setReportsRoute] = useState([]);
  const [ReportStopsPoints, setReportStopPoints] = useState([]);

  const [RouteData, setRouteData] = useState([]);
  const [dateselect, setDateSelect] = useState("Select")
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const loading = open && devices?.length === 0;
  let todate = new Date();
  let fromdate = addDays(new Date(), -30)
  const [dateRange, setDateRange] = React.useState([fromdate, todate]);
  const [startDate, endDate] = dateRange;
  const datePickerRef = React.useRef(null)
  const { data: devicelist } = useListController({
    resource: 'devices'
    // filter: { q: formValues.devicesearch}
  });
  const [isLoading, setLoading] = useState(false);
  // let todate = new Date();
  // let prvDate = new Date(
  //   todate.getFullYear(),
  //   todate.getMonth() - 1,
  //   todate.getDate()
  // );

  const [fromDate, setFromDate] = useState(fromdate);
  const [toDate, setToDate] = useState(new Date());

  // const { data : trailsreport,  page, setPage, setPerPage, ...listContext } = useListController({
  //     resource: 'trailsreport',
  //     filter: {from: filter.from, to: filter.to, deviceId: filter.deviceId },
  //     enabled: filter.form !== ""
  // });


  const fromDatePickerRef = React.useRef(null);
  const toDatePickerRef = React.useRef(null);

  const handleFromDatePicker = () => {
    const dp = fromDatePickerRef.current;
    dp.setOpen(true);
  };

  const handleToDatePicker = () => {
    const dpt = toDatePickerRef.current;
    dpt.setOpen(true);
  };

  const handleFromDate = (dates) => {
    setFromDate(dates);
  };

  const handleToDate = (dates) => {
    setToDate(dates);
  };


  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  useEffect(() => {
    setExpanded(false)
    setTrailsRouteView(false)
  }, [])

  const handleSelectDate = (event) => {
    setDateSelect(event.target.value);
  };

  function addHours(date, hours) {
    date.setHours(date.getHours() + hours);
    return date;
  }

  // useEffect(() => {
  //   if (devicelist) {

  //     // const uniqueDevices = [];
  //     // devicelist.map(device => {
  //     //     let chk_devices = uniqueDevices.find((uni_devices) => uni_devices.name === device.name);

  //     //     if (!chk_devices) {
  //     //         uniqueDevices.push(device)
  //     //     }
  //     // });

  //     const list = formatDevices(devicelist);
  //     setDevices(list);
  //   }
  // }, [loading]);



  useEffect(() => {
    const getDeviceData = () => {
      if (devicelist) {
        const list = formatDevices(devicelist);
        setDevices(list);
      }
    };
    getDeviceData();
  }, [devicelist]);



  const handleDatePicker = () => {
    const dp = datePickerRef.current
    dp.setOpen(true)
  }

  useEffect(() => {
    if (DeviceValue) {
      setFormValues({
        ...formValues,
        deviceId: DeviceValue.value,
        devicename: DeviceValue.showlabel,
      });
    }
  }, [DeviceValue])

  const formatDevices = (list) => {
    var te = list?.map((data, index) => ({
      label: (data?.id).toString() + " - " + (data?.name).toString(),
      value: data?.id,
      showlabel: data?.name,
    }));
    return te;
  };


  // useEffect(()=>{setLoading(true);},[filter])


  useEffect(() => {
    const getTrails = async () => {
      if (formValues.deviceId !== "" && formValues.deviceId !== undefined) {
        setLoading(true);
        const { data: trailsdata, total } = await vtsDataProvider.getList("trailsreport", filter)
        setTrailsData(trailsdata)
      }
    };

    getTrails(); // run it, run it

    return () => {
      setExpanded(true);
      // this now gets called when the component unmounts
    };
  }, [filter]);

  useEffect(() => { setLoading(false); }, [trailsData])


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const diffdays = (date1, date2) => {
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
  }


  const formTrackSubmit = () => {
    if (formValues.deviceId === "") {
      setFormError({ deviceId: "Select Device" })
    }
    else if (dateselect === "Select") {
      setFormError({ dateselect: "Select Date Range" })
    }
    else {
      if (dateselect === "custom") {
        if (diffdays(startDate, endDate) > 90) {
          setFormError({ datemsg: "Select 90 days difference between from and to date" })
          return;
        }
      }
      setIsSubmit(true)
      setFormError({})
    }
  }

  useEffect(() => {
    if (isSubmit === true && typeof (formError?.deviceId) === "undefined") {
      let todate = ""
      let fromdate = ""

      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds 

      if (dateselect === "last8h") {
        todate = new Date();
        fromdate = addHours(new Date(), -8)

        fromdate = (new Date(fromdate - tzoffset)).toISOString()
        todate = (new Date(todate - tzoffset)).toISOString()

      }
      else if (dateselect === "last7d") {
        todate = new Date();
        fromdate = addDays(new Date(), -7)

        fromdate = (new Date(fromdate - tzoffset)).toISOString()
        todate = (new Date(todate - tzoffset)).toISOString()
      }
      else if (dateselect === "last90d") {
        todate = new Date();
        fromdate = addDays(new Date(), -90)

        fromdate = (new Date(fromdate - tzoffset)).toISOString()
        todate = (new Date(todate - tzoffset)).toISOString()

      }
      else if (dateselect === "custom") {
        let formatFromDate = moment(fromDate).format("YYYY-MM-DD hh:mm");
        let formatToDate = moment(toDate).format("YYYY-MM-DD hh:mm");
        fromdate = moment.utc(formatFromDate).format();
        todate = moment.utc(formatToDate).format();


        // todate = endDate;
        // fromdate = startDate;
      }

     // console.log("#1234", fromdate, todate)

      // var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0,-1); // => '2015-01-26T06:40:36.181'


      setFilter({
        filter: {
          deviceId: formValues.deviceId,
          from: fromdate,
          to: todate
        }
      })
    }
  }, [isSubmit])

  const Dateformat = (source) => {
    const dt = (source !== null) ? moment(source).format("DD MMM YYYY hh:mm:ss") : "";
    return dt;
  };

  const msToTime = (duration) => {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;

    // + "." + milliseconds;
  }



  var date = new Date();
  var formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
  var placeholderDate = StaringDate + " - " + formatedDate;


  const playTrails = async (playData) => {
    setPlayRoutesData(playData);
    // }

    // useEffect(() => {
    //   const getReportsRoute = async () => {

    const reportrouteparams = {
      filter:
      {
        deviceId: playData.deviceId,
        from: playData.startTime,
        to: playData.endTime
      }
    }
    if (playData.deviceId !== "" && playData.deviceId !== undefined) {
      const { data: reportsroutedata } = await vtsDataProvider.getList("reportsroute", reportrouteparams)
      setReportsRoute(reportsroutedata)
      const { data: stopPoints } = await vtsDataProvider.getList("trailsstoppoints", reportrouteparams)
      setReportStopPoints(stopPoints)
    }

    setTrailsRouteView(true);

  };

  //   getReportsRoute(); // run it, run it

  //   return () => {
  //       setTrailsRouteView(true);
  //     //  setExpanded(true);
  //     // this now gets called when the component unmounts
  //   };
  // }, [playRoutesData]);

  //   const formatReportsRoute = (list) => {
  //     var route = list?.map((data) => ({
  //         speed: data?.speed,
  //         deviceTime: data?.deviceTime,
  //     }));
  //     return route;
  //   };

  //   useEffect(()=>
  //   {
  //    const  routedata = formatReportsRoute(ReportsRoute)
  //   // console.log(routedata)
  //    setRouteData(routedata)

  //   },[ReportsRoute])


  const playTrailsBack = () => {
    setReportsRoute([]);
    setReportStopPoints([]);
    setTrailsRouteView(false);
    setExpanded(true)
  }




  return (<>
    {TrailsRouteView === false ?
      <>


        <Box className="map-content">
          <Box>


            <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
              <Card
                sx={{
                  minHeight: 52,
                  minWidth: 250,
                  display: 'flex',
                  width: '33%',
                  flexDirection: 'column',
                  marginRight: '10px',
                  '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                  },
                }}
              >

                <Box
                  sx={{
                    overflow: 'inherit',
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& .icon': {
                      color: theme =>
                        theme.palette.mode === 'dark'
                          ? 'inherit'
                          : '#dc2440',
                    },
                  }}
                >
                  {expanded === false ?
                    <Box textAlign="left">
                      <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                        sx={{
                          marginBottom: '15px'
                        }}>Trails
                      </Typography>
                      <Box sx={{ height: "380px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box sx={{ marginTop: '1em' }} className="select-device">
                              <FormLabel>Device</FormLabel>
                              <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                disablePortal
                                id="deviceId"
                                name="deviceId"
                                options={devices}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.value}>
                                    {option.showlabel}
                                  </li>
                                )}

                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Device"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                                open={open}
                                onOpen={() => {
                                  setOpen(true);
                                }}
                                onClose={() => {
                                  setOpen(false);
                                }}
                                loading={loading}
                                getOptionLabel={(option) => option.showlabel}
                                onChange={(event, newValue) => {
                                  setDeviceValue(newValue);
                                }}
                                // onChange={handleInputChange}
                                value={DeviceValue}
                              />
                              <div style={errorstyle}>
                                {formError.deviceId}
                              </div>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ marginTop: '12px !important' }} className="select-device datefilter">
                              <FormLabel>Date Filter</FormLabel><br></br>
                              <Select
                                sx={{
                                  background: "#f0f0ff",
                                  width: "100% !important",
                                  minWidth: "100%", padding: "0!important"
                                }}

                                name="dateselect"
                                value={dateselect}
                                onChange={handleSelectDate}
                              >
                                <MenuItem key={0} value="Select" selected>
                                  Select
                                </MenuItem>
                                <MenuItem key={1} value="last8h">
                                  Last 8 hours
                                </MenuItem>
                                <MenuItem key={2} value="last7d">
                                  Last 7 days
                                </MenuItem>
                                <MenuItem key={2} value="last90d">
                                  Last 90 days
                                </MenuItem>
                                <MenuItem key={2} value="custom">
                                  Custom
                                </MenuItem>
                              </Select>
                              <div style={errorstyle}>
                                {formError.dateselect}
                              </div>
                            </Box>
                          </Grid>
                          {dateselect === "custom" ?
                            <> <Grid item xs={12}>
                              <Box className="date-range top-down" sx={{ marginTop: '0em !important' }}>
                                <FormControl sx={{ marginTop: '0em !important' }}>
                                  <FormLabel>Date From</FormLabel>
                                  <DatePicker
                                    selected={fromDate}
                                    onChange={(date) => handleFromDate(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="dd/MM/yyyy hh:mm aa"
                                    ref={fromDatePickerRef}
                                    maxDate={new Date()}
                                  />
                                  <IconButton
                                    className="cal-icon"
                                    onClick={handleFromDatePicker}
                                  >
                                    <CalendarIcon />
                                  </IconButton>
                                </FormControl>
                              </Box></Grid>
                              <Grid item xs={12}>
                                <Box className="date-range top-down" sx={{ marginTop: '0em !important' }}>
                                  <FormControl sx={{ marginTop: '0em !important' }}>
                                    <FormLabel>Date To</FormLabel>
                                    <DatePicker
                                      selected={toDate}
                                      onChange={(date) => handleToDate(date)}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={1}
                                      timeCaption="Time"
                                      dateFormat="dd/MM/yyyy hh:mm aa"
                                      ref={toDatePickerRef}
                                      maxDate={new Date()}
                                    />
                                    <IconButton
                                      className="cal-icon"
                                      onClick={handleToDatePicker}
                                    >
                                      <CalendarIcon />
                                    </IconButton>
                                  </FormControl>
                                </Box>
                              </Grid></>
                            // <Grid item xs={12}>
                            //   <Box className="date-range top-down" sx={{ marginTop: '0em !important' }}>
                            //     <FormControl sx={{ marginTop: '0em !important' }}>
                            //     <FormLabel>Select date range</FormLabel>
                            //       <DatePicker
                            //         selectsRange={true}
                            //         startDate={startDate}
                            //         endDate={endDate}
                            //         onChange={(update) => {
                            //           setDateRange(update);
                            //         }}
                            //         showTimeSelect
                            //         timeFormat="HH:mm"
                            //         timeIntervals={1}
                            //         timeCaption="Time"
                            //         dateFormat="dd/MM/yyyy hh:mm aa"
                            //         placeholderText={placeholderDate}
                            //         isClearable={true}
                            //         ref={datePickerRef}
                            //         maxDate={new Date()}
                            //       />
                            //       <IconButton className="cal-icon" onClick={handleDatePicker}>
                            //         <CalendarIcon />
                            //       </IconButton>
                            //     </FormControl>
                            //     <div style={errorstyle}>
                            //       {formError.datemsg}
                            //     </div>
                            //   </Box>
                            // </Grid>
                            : ""}
                          <Grid item xs={12}>
                            <Box sx={{ marginTop: '1em' }}>
                              <CardActions disableSpacing className='expand-btn' sx={{ paddingLeft: "0 !important" }}>
                                <Button label="Track" className='show-btn' expand={expanded}
                                  aria-expanded={expanded}
                                  aria-label="show" onClick={formTrackSubmit}></Button>
                              </CardActions>

                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    :

                    <Box textAlign="left" className='trail-track'>
                      <div style={{ display: "flex" }}>
                        <ArrowIcon className='arrow-icon' onClick={() => { setExpanded(false); setIsSubmit(false); }} sx={{ marginBottom: "15px" }} />

                      </div>
                      <div className="scrollbar">
                        <div style={{ display: "flex" }}>
                          <CarIcon sx={{ fontSize: "18px !important" }} />
                          <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                            sx={{
                              marginBottom: '15px'
                            }}>{formValues.devicename}
                          </Typography>
                        </div>


                        {
                          isLoading === true ? <div>...Loading</div> :
                            trailsData.length > 0 ?
                              trailsData.map((trails, index) => {
                                return (<>
                                  <Box>
                                    <Grid item xs={12}>
                                      <ul class="progress">
                                        <li class="progress__item progress__item--completed">
                                          <p class="progress__title">{Dateformat(trails.startTime)}</p>
                                          <p class="progress__info">{trails.startAddress}</p>
                                        </li>
                                        <li class="progress__item progress__item--active">
                                          <p class="progress__title">{Dateformat(trails.endTime)}</p>
                                          <p class="progress__info">{trails.endAddress}</p>
                                        </li>
                                      </ul>
                                    </Grid>
                                  </Box>
                                  <div className="trail-track-det">
                                    <Box sx={{ display: 'flex', padding: "10px 0" }}>
                                      <RouteIcon />
                                      <Typography color="textSecondary" variant="h" component="p"
                                        sx={{ fontSize: '14px', width: "46px", marginRight: "5px" }}>Distance
                                      </Typography>
                                      <Typography color="textSecondary" variant="h" component="h4"
                                        sx={{
                                          marginLeft: '53px'
                                        }}>{(trails.distance) !== undefined ? ((trails.distance / 1000).toFixed(2)) : 0} Km

                                      </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', padding: "10px 0" }}>
                                      <TimeIcon />
                                      <Typography color="textSecondary" variant="h" component="p"
                                        sx={{ fontSize: '14px', width: "46px", marginRight: "5px" }}>Duration
                                      </Typography>
                                      <Typography color="textSecondary" variant="h" component="h4"
                                        sx={{
                                          marginLeft: '53px'
                                        }}>{msToTime(trails.duration)} Hrs
                                      </Typography>
                                    </Box>
                                  </div>
                                  <Box sx={{ marginBottom: "1rem" }}>
                                    <Button label="Play trails on map" onClick={() => playTrails(trails)} className='play-btn' startIcon={<PlayIcon />}></Button>
                                  </Box>
                                </>
                                )

                              })
                              : <div style={errorstyle}>Trails Not Available</div>
                        }
                      </div>
                    </Box>
                  }
                </Box>
              </Card>

              {/* Map view */}
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '73%',
                  '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                  },
                }}
              >
                <TrailsRouting />
              </Card>

            </Box>
          </Box>
        </Box>
      </>
      :

      <div>

        <TrailsRouting playRoutesData={playRoutesData} playTrailsBack={playTrailsBack} reportsRoute={ReportsRoute} TrailsRouteView={TrailsRouteView} stopPoints={ReportStopsPoints} />
        {/* <MapContainer center={[21.35516, 81.28199]} zoom={13} style={{ height: "200vh" }} zoomControl={false}>
            <TileLayer
              url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
              maxZoom={20}
              subdomains={['mt1', 'mt2', 'mt3']}
            />
            <ZoomControl position="bottomleft" />
            <TrailsRouting />
          </MapContainer> */}




      </div>
    }
  </>
  );
}


