import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreHorizOutlined";
import CircleIcon from "@mui/icons-material/Circle";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useGetList, useListController, Button } from "react-admin";
import { exportDevice } from "../providers/reportProvider";

export default function CommunicatingChart() {
  const [GPSData, setGPSData] = useState([]);

  const { data: chartData } = useGetList("communicationstatus");

  const handleExport = async () => {
    await exportDevice();
  };

  useEffect(() => {
    if (!chartData) return;
    formatData(chartData);
  }, [chartData]);

  const formatData = (dat) => {
    const gData = dat?.map((obj, indexx) => {
      obj["name"] = obj?.label + " days";
      obj["count"] = obj?.deviceCount;

      return obj;
    });
    setGPSData(gData);
  };

  return (
    <Paper className="non-commun-barchart">
      <div className="communicate-right">
        <Typography
          color="textSecondary"
          variant="h"
          component="h4"
          className="Card-Title"
          sx={{
            marginBottom: "15px",
          }}
        >
          Non Communicating device
        </Typography>
        {/* <Typography color="textSecondary" className="red-color">
          <CircleIcon /> GPS
        </Typography>
        <Typography color="textSecondary" className="blue-color">
          <CircleIcon /> GPRS
        </Typography> */}
        {/* <MoreIcon style={{ float: "right",marginTop:"-2px" }} /> */}
      </div>
      {GPSData?.length > 0 ? (
        // <BarChart
        //   width={400}
        //   height={200}
        //   data={GPSData}
        //   margin={{
        //     top: 5,
        //     right: 30,
        //     left: 20,
        //     bottom: 5,
        //   }}
        // >
        //   <CartesianGrid  />
        //   <XAxis dataKey="name" />
        //   <YAxis />
        //   <Tooltip />
        //   <Legend />
        //   <Bar dataKey="GPRS" fill="#6E26FA" />
        //   <Bar dataKey="GPS" fill="#F25555" />
        // </BarChart>
        <>
          <BarChart
            width={400}
            height={200}
            data={GPSData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#6E26FA" />
          </BarChart>
          <Button
            className="show-btn"
            onClick={handleExport}
            sx={{ float: "right !important", top: "-40px" }}
          >
            Export
          </Button>
        </>
      ) : (
        <Typography>No Data</Typography>
      )}
    </Paper>
  );
}
