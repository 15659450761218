import React, { useState, useEffect } from 'react';
import { Confirm, TextField, useRefresh, useListController, ReferenceField, Datagrid, useRecordContext, ListContextProvider, TextInput, Pagination, ListToolbar, TopToolbar, Button } from "react-admin";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import {
  Grid,
  Card
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import "../views/route/route.css";
import AddButton from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MapView from './../components/mapview';
import SelectedList from './../components/Accordian';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRowsIcon from '@mui/icons-material/TableRowsOutlined';
import DistanceIcon from '@mui/icons-material/RouteOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import PointTypeIcon from '@mui/icons-material/PinDropOutlined';
import { CreateRoute } from './../components/CreateRoute';
import { LinearProgress } from '@mui/material';
import VTSListToolbar from '../components/VTSListToolbar';
import moment from "moment";
import { PanelRoutelist } from '../utils/PanelRoutelist';
import SendNotification from "../utils/sendNotification";
import vtsDataProvider from './../providers/dataProvider'
import useMediaQuery from '@mui/material/useMediaQuery';


const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  flexRow: { display: 'flex', flexDirection: 'row' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};
const Buttonstyle = {
  position: 'absolute',
  background: '#4646F2',
  color: '#fff',
  zIndex: 1,
  textTransform: 'capitalize',
  width: '130px',
  border: '1px solid #4546F2',
  position: 'absolute',
  right: '112px'
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const defaultValues = {
  name: "",
  phone: "",
  email: "",
  password: "",

};

const position = [51.505, -0.09]


const TabPanel = props => {

  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && (

        <Typography>{children}</Typography>

      )}
    </React.Fragment>
  )
}




export const RouteList = ({ createper, updateper, deleteper }) => {
  const [notifyData, setNotifyData] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [RouteId, setRouteId] = useState(0)
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  });
  const refresh = useRefresh();
  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const matches = useMediaQuery('(min-width:1800px)');

 const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const { data, isLoading, page, setPage, setPerPage, ...listContext } = useListController({
    resource: 'route'
  });

  const filters = [<TextInput label="Search" source="q" size="small" alwaysOn />];
  const filterValues = { q: filter };
  const setFilters = filters => setFilter(filters.q);

  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const RouteActions = () => (
    <TopToolbar className='route-toolbar'>
      {/* <Button label="Refresh" className='refreshbtn' startIcon={<RefreshIcon />} onClick={() => {listContext.filterValues.q =""; listContext.setFilters({}) }}/> */}
      {createper && (<><AddButton className="create-btn" startIcon={<AddIcon />} sx={Buttonstyle} expand={expanded} aria-expanded={expanded} aria-label="show" onClick={() => { setExpanded(true); }}>Create Route</AddButton></>)}
    </TopToolbar>
  );

  const RoutnameField = ({ source }) => {
    const record = useRecordContext();
    return (
      <span style={{ color: "#4646F2", display: "flex" }}><RouteIcon /><span>{record[source]}</span>
      </span>
    )
  };

  const handleDeleteClick = (event, deleteId) => {
    setDeleteDialog({
      open: true,
      deleteId: deleteId
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete('route', { id: deleteDialog.deleteId }, setNotifyData, "Route Deleted Succesfully", "Failed to Delete");
      handleConfirmClose();
    }
  };

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
  };


  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <DeleteIcon
          onClick={(event) => handleDeleteClick(event, record[source])}
        />
      </div>
    );
  };

  const handleEditClick = (event, RouteId) => {
    setRouteId(RouteId);
    setExpanded(true);
  }

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon
          onClick={(event) => handleEditClick(event, record[source])}
        />
      </div>
    );
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = (record[source] !== null && record[source] !== "" && record[source] !== undefined) ? moment(record[source]).utc().format('DD MMM YYYY, HH:mm:ss') : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  if (isLoading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  } else
    return (
      <>
        {expanded === false ?
          <ListContextProvider debounce={8000} value={{ data, filterValues, page, setFilters, setPage, setPerPage, ...listContext }}>
            <VTSListToolbar filters={filters} actions={<RouteActions />} />
            {/* {(data === undefined || (Array.isArray(data) === true && data.length === 0)) ? <><div className='no-records'>Data Not Available</div></> :
              <> */}
              {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
             <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
  	        )}
              <Box className="list-content">
                <Datagrid bulkActionButtons={false} expand={<PanelRoutelist />}  empty={<CustomEmpty />}>
                  {/* <TextField label="Route Id" source="id" /> */}
                  <RoutnameField label="Route Name" source="name" />
                  {/* <FunctionField label="Start Lat Long"  render={
                        record => `${record.legs.nameFrom}, ${record.legs.nameTo}`
                    } /> */}
                  <TextField label="Route Type" source="routeType" />
                  <TextField label="Stoppage#" source="numStoppage" />
                  <TextField label="Planned Distance (Km)" source="estimatedDistance" />
                  <TextField label="Estimated Time" source="estimatedDuration" />
                  <TextField label="Description" source="description" />
                  <ReferenceField label="Created By" source="createdBy" reference="users" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  {/* <TextField label="Created by" source="createdBy" /> */}
                  <DateSetter label="Created On" source="createdOn" />
                  {/* <ReferenceField label="Modified By" source="modifiedBy" reference="users">
                        <TextField source="name" />
                      </ReferenceField> */}
                  {/* <TextField label="Modified by" source="modifiedBy" /> */}
                  <DateSetter label="Modified On" source="lastUpdate" />
                  <span label="Action" className="action-icons">
                    {updateper && (<><CustomEditButton source="id" /></>)}
                    {deleteper && (<><CustomDeleteButton source="id" /></>)}
                  </span>
                </Datagrid>
              </Box>
                {/* </>} */}
          </ListContextProvider>
          :
          <CreateRoute setExpanded={setExpanded} RouteId={RouteId} setRouteId={setRouteId} listContext={listContext}></CreateRoute>
        }

        <Confirm
          isOpen={deleteDialog?.open}
          title="Delete Route"
          content="Are you sure you want to delete the Route?"
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmClose}
        />
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
      </>
    );
}
