import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
} from "react-admin";

import users from "../users/index";
import vehicles from "../vehicles/index";
import trails from "../trails/index";
import overview from "../overview/index";
import reports from "../reports/index";
import panic from "../panic/index";
import groups from "../group/index";
import route from "../route/index";
import geofence from "../geofence/index";
import alarm from "../alarm/index";
import cpanel from "../cpanel/index";
import SubMenu from "./SubMenu";
import Logo from "../../images/logo.svg";
import TransportLogo from "../../images/transport_department_logo.jpeg";
import { TitleContext } from "../../utils/TitleContext";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

let MenuName =
  "menuOverview" |
  "menuTrails" |
  "menuVehicles" |
  "menuUsers" |
  "menuReports" |
  "menuPanic" |
  "menuGroups" |
  "menuRoute" |
  "menuGeofence" |
  "menuAlarm" |
  "menuControlPanel";
const dense = false;
const Menu = (MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuVehicles: true,
    menuUsers: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (MenuName) => {
    setState((state) => ({ ...state, [MenuName]: !state[MenuName] }));
    getTitle(MenuName);
  };

  const navigate = useNavigate()
  const { setTitle } = React.useContext(TitleContext);

  function getTitle(name) {
    let temp = "";
    if (name == "menuGroups") temp = "Groups/Assets";
    else if (name == "menuUsers") temp = "User/Roles";
    else if (name == "menuOverview") temp = "Dashboard";
    else temp = name.slice(4);
    setTitle(temp);
  }

  const auth = usePermissions();

  const tabPermissions = auth?.permissions?.user_role?.roles?.view;
  const ReadPermissions = auth?.permissions?.user_role?.roles?.permission;
  const readpervalue = "4,12,15,6,5,14";
  const controlreadvalue = "8,1,9";

  const handleRedirect = () =>{
    navigate("/overview");
  }


  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
      className="Left-menu"
    >
      <div className="logo-bar" style={{cursor: "pointer"}} onClick={handleRedirect}>
        <img src={TransportLogo} />
        <Typography variant="h5" component="h2" align="center">
          Vehicle Tracking System
        </Typography>
      </div>
      {tabPermissions &&
        (tabPermissions["dashboard_overall"] == 1 || readpervalue.includes(ReadPermissions["dashboard"])) && (
          <>
            <MenuItemLink
              to="/overview"
              onClick={() => handleToggle("menuOverview")}
              primaryText={translate(`Overview`, {
                smart_count: 2,
              })}
              leftIcon={<overview.icon />}
            />
            <SubMenu name="Track" />
          </>
        )}

      {tabPermissions &&
        (tabPermissions["vehicle_list"] == 1 ||
          tabPermissions["vehicle_map"] == 1 || 
          readpervalue.includes(ReadPermissions["device"])) && (
          <MenuItemLink
            to="/vehicles"
            onClick={() => handleToggle("menuVehicles")}
            primaryText={translate(`Vehicles`, {
              smart_count: 2,
            })}
            leftIcon={<vehicles.icon />}
          />
        )}
      {tabPermissions &&
        (tabPermissions["trails"] == 1 ||
          tabPermissions["trip_trail"] == 1) && (
          <MenuItemLink
            to="/trails"
            onClick={() => handleToggle("menuTrails")}
            primaryText={translate(`Trails`, {
              smart_count: 2,
            })}
            leftIcon={<trails.icon />}
          />
        )}
      {tabPermissions &&
        (tabPermissions["by_vehicle"] == 1 || 
        tabPermissions["by_group"] == 1 || 
        tabPermissions["schedule"] == 1 || 
        tabPermissions["audit_trail"] == 1 || 
        readpervalue.includes(ReadPermissions["schedule"]) ||
        readpervalue.includes(ReadPermissions["audit_trail"])) && (
          <MenuItemLink
            to="/reports"
            onClick={() => handleToggle("menuReports")}
            primaryText={translate(`Reports`, {
              smart_count: 2,
            })}
            leftIcon={<reports.icon />}
          />
        )}
      {tabPermissions &&
        (tabPermissions["panic_status"] == 1 ||
          tabPermissions["panic_sos"] == 1 ||
          readpervalue.includes(ReadPermissions["sos"])) && (
          <MenuItemLink
            to="/PanicDetails"
            onClick={() => handleToggle("menuPanic")}
            primaryText={translate(`Panic`, {
              smart_count: 2,
            })}
            leftIcon={<panic.icon />}
          />
        )}

      <SubMenu name="Manage" />
      {tabPermissions &&
        (tabPermissions["entity"] == 1 ||
          tabPermissions["group"] == 1 ||
          readpervalue.includes(ReadPermissions["vehicle_registration"]) ||
          readpervalue.includes(ReadPermissions["group"])) && (
          <MenuItemLink
            to="/groups"
            onClick={() => handleToggle("menuGroups")}
            primaryText={translate(`Groups/Assets`, {
              smart_count: 2,
            })}
            leftIcon={<groups.icon />}
          // dense={dense}
          />
        )}
      {tabPermissions &&
        (tabPermissions["route_list"] == 1 ||
          tabPermissions["route_assign"] == 1 ||
          readpervalue.includes(ReadPermissions["route"]) ||
          readpervalue.includes(ReadPermissions["trip"])) && (
          <MenuItemLink
            to="/route"
            onClick={() => handleToggle("menuRoute")}
            primaryText={translate(`Route`, {
              smart_count: 2,
            })}
            leftIcon={<route.icon />}
          />
        )}
      {tabPermissions &&
        (tabPermissions["geofence_configuration"] == 1 ||
        readpervalue.includes(ReadPermissions["geofence"])) && (
          <MenuItemLink
            to="/geofences"
            onClick={() => handleToggle("menuGeofence")}
            primaryText={translate(`Geofence`, {
              smart_count: 2,
            })}
            leftIcon={<geofence.icon />}
          />
        )}
      {tabPermissions &&
        (tabPermissions["alarm_configuration"] == 1 || 
        tabPermissions["alarm_log"] == 1 ||
        readpervalue.includes(ReadPermissions["notification"])) && (
          <MenuItemLink
            to="/alarm"
            onClick={() => handleToggle("menuAlarm")}
            primaryText={translate(`Alarm`, {
              smart_count: 2,
            })}
            leftIcon={<alarm.icon />}
          />
        )}
      {tabPermissions &&
        (tabPermissions["user"] == 1 || 
        tabPermissions["role"] == 1 ||
        readpervalue.includes(ReadPermissions["user"]) ||
        readpervalue.includes(ReadPermissions["role"])) && (
          <MenuItemLink
            to="/UsersDetails"
            onClick={() => handleToggle("menuUsers")}
            primaryText={translate(`User/Roles`, {
              smart_count: 2,
            })}
            leftIcon={<users.icon />}
          // dense={dense}
          />
        )}
      {tabPermissions &&
        (tabPermissions["control_panel"] == 1 ||
        controlreadvalue.includes(ReadPermissions["control_panel"])) && (<MenuItemLink
          to="/ControlPanel"
          onClick={() => handleToggle("menuControlPanel")}
          primaryText={translate(`Control panel`, {
            smart_count: 2,
          })}
          leftIcon={<cpanel.icon />}
        />)}
    </Box>
  );
};

export default Menu;
