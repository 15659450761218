/* eslint-disable import/no-anonymous-default-export */
import L  from 'leaflet';


function validateLatLong(lat, lng) {    
    let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
    
    return pattern.test(lat) && pattern.test(lng);
}

 
function containsLatLong(lat, lng, bounds) {
  if(!exists(lat) || !exists(lng))
    return false;
  var point = [lat,lng]
  return  checkPointInBound(bounds,point);
  // var latlng = L.latLng(lat, lng);
  // return exists(bounds) ? bounds.contains(latlng) : false;
}

function checkPointInBound(bound, point) {
  if(!bound){console.log("Pass Bounds!!"); return false;
  }else{
      var bb = { ix: bound._sw.lng, iy: bound._sw.lat, ax: bound._ne.lng, ay: bound._ne.lat }; // Vector Bound
      if (bb.ix <= point[1] && point[1] <= bb.ax && bb.iy <= point[0] && point[0] <= bb.ay) {
          //console.log("True!! Inside Bound");
          return true;
      }else{
          //console.log("False!! Outside Bound");
          return false;
      }
  }
}

/**
 * Check if obj is not  null or  undefined 
 * @param {} obj 
 * @returns 
 */
function exists(obj) {
  return obj && obj !== undefined && obj !== null;
}

/**
 * Check if object is null or undefined or in case of array, 0 length
 * @param {} obj 
 * @returns 
 */
function isEmpty(obj) {
  return !exists(obj) || (obj instanceof Array ? obj.length === 0 : Object.keys(obj).length === 0);
}


function  angleFromCoordinates( lat1,  long1,  lat2,  long2) {

let dLon = (long2 - long1);

let y = Math.sin(dLon) * Math.cos(lat2);
let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
      * Math.cos(lat2) * Math.cos(dLon);

let brng = Math.atan2(y, x);

brng = brng * 180 / Math.PI;
brng = (brng + 360) % 360;
brng = 270 - brng; // count degrees counter-clockwise - remove to make clockwise

return brng;
}

function empty(el) {
	while (el.firstChild) {
		el.removeChild(el.firstChild);
	}
}

export  { validateLatLong, containsLatLong, exists, isEmpty, angleFromCoordinates, empty };