import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Box, Typography, FormControl, FormLabel } from "@mui/material";
import "./panic.css";
import { PanicChart } from "../../components/PanicChart";
import VTSListToolbar from '../../components/VTSListToolbar';
import useTabPermissions from "../../hooks/useTabPermissions";
import { Pagination, TextField, Datagrid, useRecordContext, useListController, useRefresh, TextInput, LinearProgress, ListContextProvider, } from "react-admin";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import moment from "moment";
import { getVehicleLocation } from "../../providers/dataProvider";
import EditPanicDialog from "../../components/EditPanicDialog";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from "react-router-dom";

const TabPanel = (props) => {
  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && <Typography>{children}</Typography>}
    </React.Fragment>
  );
};

export const PanicDetails = () => {
  const [value, setValue] = React.useState(0);
  const [selectDate, setSelectDate] = useState([]);

  // const [selected, setSelected] = useState("all");
  const [selected, setSelected] = useState({
    status: "all",
    from_page: null
  });

  const [filter, setFilter] = useState("");
  const [panicOpen, setPanicOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const location = useLocation();

  const tabView = useTabPermissions('panic')


  useEffect(() => {
    if (location?.state?.type == "map") {
      setValue(1);
      setSelected({
        status: "pending",
        from_page: null
      })
    }
  }, [location?.state]);



  let todate = new Date();
  let prvDate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1  );
  const [dateRange, setDateRange] = React.useState([prvDate, todate]);
  const [startDate, endDate] = dateRange;

  const refresh = useRefresh();

  const datePickerRef = useRef(null);
  const selectRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // setSelected("all")
    setSelected({
      status: "all",
      from_page: null
    })
  };

  const handleDatePicker = () => {
    const dp = datePickerRef.current;
    dp.setOpen(true);
  };



  var date = new Date();
  var formatedDate = `${date.getDate()}/${date.getMonth() + 1
    }/${date.getFullYear()}`;
  var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  var placeholderDate = StaringDate + " - " + formatedDate;


  const handleTabChange = (event, status, newValue, pyear) => {
    setValue(newValue);
    let curdate = new Date();
    if(pyear === curdate.getFullYear())
    {
        let todate = new Date();
        let prvDate = new Date(todate.getFullYear(), todate.getMonth() - 1, 1  );
        setDateRange([prvDate, todate]);
    }
    else
    {
      let todate = new Date(pyear,11,31);
      let prvDate = new Date(pyear, 0, 1);

     // console.log("selecteddate",prvDate, todate)
      setDateRange([prvDate, todate]);
    }
    // setSelected(status)
    setSelected({
      status: status,
      from_page: "chart"
    })
  };

  
  const statusTypes = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Resolved",
      value: "resolved",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
    {
      label: "Acknowledged",
      value: "acknowledged",
    },
  ];

  const handleSelectChange = (e) => {
    selectRef.current.value = e.target.value;
    // setSelected(e.target.value)
    setSelected({
      status: e.target.value,
      from_page: null
    })
  };

  const handleUpdateDate = (update) => {
    setDateRange(update)
  };

  // useEffect(() => {  
  //   dateRange[0] && dateRange[1] && setSelectDate(dateRange)
  // }, [dateRange])  

  const getPanicData = () =>{
    setSelectDate(dateRange)
    if(selected?.from_page == "chart"){
      setSelected({
      ...selected,
      from_page: null
    })
   }
  }


  useEffect(() => {
    dateRange[0] && dateRange[1] && getPanicData()
  }, [dateRange])



    

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(parseInt(event.target.value, 10));
     setPerPage(event.target.value);
   };
   
  const filters = [
    <Box className="status-b" style={{ marginLeft: "12px" }} alwaysOn>
      <FormLabel>Status</FormLabel>
      <Select
        name="status"
        value={selected?.status}
        onChange={handleSelectChange}
        ref={selectRef}
        displayEmpty
        renderValue={
          selected?.status !== null ? undefined : () => <span>Select Status</span>
        }
      >
        {statusTypes?.map((status, index) => (
          <MenuItem key={index} value={status?.value}>
            {status?.label}
          </MenuItem>
        ))}
      </Select>
    </Box>,
    <Box className="date-range" alwaysOn>
      <FormControl>
        <DatePicker
          name="date"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => handleUpdateDate(update)}
          placeholderText={placeholderDate}
          isClearable={true}
          ref={datePickerRef}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
        />
        <IconButton className="cal-icon" onClick={handleDatePicker}>
          <CalendarIcon />
        </IconButton>
      </FormControl>
    </Box>,
    <TextInput label="Search" source="q" size="small" alwaysOn />,
  ];



  const { data, isLoading, page, setPage, setPerPage, ...listContext } =
    useListController({
      resource: "panicsos",
      filter: {
        selected: selected,
        date: selectDate
      },
    });



  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const setFilters = (filters) => setFilter(filters.q);
  const sort = { field: "id", order: "ASC" };
  const filterValues = { q: filter };

  const handlePanicClose = () => {
    setPanicOpen(false);
    refresh();
  };

  const [currentPanic, setCurrentPanic] = useState({
    id: null,
    deviceId: null,
    type: null,
    eventTime: null,
    positionId: null,
    geofenceId: null,
    maintenanceId: null,
    location: null,
    remark: null,
    action: null,
    status: null,
    attributes: {
      alarm: "sos",
    },
  });

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon onClick={(event) => handleEditClick(event, record, false)} />
      </div>
    );
  };

  const CustomViewButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <ViewIcon onClick={(event) => handleEditClick(event, record, true)} />
      </div>
    );
  };



  const handleEditClick = async (event, row, readOnly) => {
    const address = {};
    if (row?.latitude && row?.longitude) await getVehicleLocation(row, address);
    setCurrentPanic({
      ...row,
      readOnly: readOnly,
    });
    setPanicOpen(true);
  };

  const handleAddress = async (record) => {
    const address = {};
    if (!record?.latitude && !record?.longitude) return;
    await getVehicleLocation(record, address);
    document
      .getElementById(`addr_${record?.id}`)
      ?.classList?.remove("address_styling");
    document.getElementById(`addr_${record?.id}`).innerText = address?.address;
  };

  const AddressField = ({ source }) => {
    const record = useRecordContext();
    return (
      <>
        {record?.latitude != 0 && record?.longitude != 0 && (
          <span
            id={`addr_${record?.id}`}
            className="address_styling"
            onClick={() => handleAddress(record)}
          >
            Show Address
          </span>
        )}
      </>
    );
  };


  const StatusField = ({ source }) => {
    const record = useRecordContext();
    return record ? <span>{record["status"]}</span> : null;
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = record[source]!==null?moment(record[source]).format("DD MMM YYYY, HH:mm:ss"):"";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };


  




  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else
    return (
      <>
        <Box sx={{ width: "100%", typography: "body1" }} className="panic-left">
          <ListContextProvider
            value={{
              data,
              page,
              setPage,
              setPerPage,
              filterValues,
              setFilters,
              ...listContext,
            }}
          >

            <Tabs value={value} onChange={handleChange}>
              {tabView && (tabView?.viewPanic) && <Tab value={0} label="Panic Status" />}
              {tabView && (tabView?.viewPanicSOS) && <Tab value={1} label="SOS" />}
            </Tabs>
            {tabView && (tabView?.readPanic) && <TabPanel value={value} index={0}>
              <PanicChart handleTabChange={handleTabChange} />
            </TabPanel>}
            {tabView && (tabView?.readPanicSOS) && <TabPanel value={value} index={1}>
              {/* <PanicSOSList selected={selected} setSelected={setSelected} /> */}
              {/* <PanicSOSList stat={stat} setStat={setStat}/> */}
              <VTSListToolbar
                filters={filters}
                date={datePickerRef}
                select={selectRef}
                setSelected={setSelected}
                setDateRange={setDateRange}
              />
            {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
             <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
  	          )}
              <Box className="list-content">
                <Datagrid empty={<CustomEmpty />} bulkActionButtons={false}>
                  {/* <TextField label="Serial No" source="id" /> */}
                  <TextField label="Vehicle No" source="vehicleNo" />
                  <DateSetter label="Time" source="eventTime" />
                  <AddressField label="Location" />
                  <TextField label="Latitude" source="latitude" />
                  <TextField label="Longitude" source="longitude" />
                  <TextField label="SOS Action" source="action" />
                  <StatusField source="status" />
                  <DateSetter label="Updated On" source="updateOn" />
                  <TextField label="Remark" source="remark" />
                  <TextField label="Permit Holder" source="permitHolder" />
                  <TextField label="Contact" source="permitHolderPhone" />
                  {/* <PanicAction label="Action" source="" /> */}
                  <span label="Action" className="action-icons">
                    <CustomViewButton source="id" />
                    <CustomEditButton source="id" />
                  </span>
                </Datagrid>
              </Box>
              
            </TabPanel>}
          </ListContextProvider>
        </Box>
        <EditPanicDialog
          panicOpen={panicOpen}
          handlePanicClose={handlePanicClose}
          currentPanic={currentPanic}
        />
      </>
    );
};