import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';

import VTSFilterForm from './VTSFilterForm';
import { FilterContext } from 'react-admin';

const VTSListToolbar = (props) => {
const { filters, actions, className, date, select, setGroupId,setRtoCode, setVltdModel, setVltdMake, setSelected,setDateRange, type, ...rest } = props;

return <FilterContext.Provider value={filters}>
            <Root className={className}>
                <VTSFilterForm setGroupId={setGroupId} filters={filters} date={date} select={select} setRtoCode={setRtoCode} type={type} setVltdModel={setVltdModel} setVltdMake={setVltdMake} setSelected={setSelected} setDateRange={setDateRange} />
                <span />
                {actions &&
                    React.cloneElement(actions, {
                        ...rest,
                        ...actions.props,
                    })}
            </Root>
        </FilterContext.Provider>

}


const Root = styled(Toolbar, {
    name: "RaListToolbar",
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    minHeight: 'auto',
    [theme.breakpoints.up('sm')]: {
        paddingRight: 0,
    },
    [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
    },
}));

export default VTSListToolbar;