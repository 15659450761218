import React, { useEffect } from "react";
import { useState } from "react";
import Reaptcha from 'reaptcha';
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { Avatar, Button, Card, CardActions } from "@mui/material";
import { Link } from "react-admin";
import Logo from "../../images/logo.svg";
import TransportLogo from "../../images/transport_department_logo.jpeg";
import Grid from "@mui/material/Grid";
import EyeIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
  email
} from "react-admin";

import Box from "@mui/material/Box";
import { Spinner } from "../../components/spinner";
import SendNotification from "../../utils/sendNotification";

const REACT_APP_SITE_KEY = "6LeBirsjAAAAAAn_fua9ErE-O_0f4oVXbMMq3SV-";

const errorstyle = {
  color: "#d32f2f",
  fontSize: "12px",
  display: "block"
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [notifyData, setNotifyData] = useState({});
  const [loggedInState, setLoggedInState] = useState();
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [setCaptcha, setRecaptcha] = useState(true);
  const [emailError, setEmailError] = useState('')
  const [pwdError, setPwdError] = useState('')

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };


  const validateEmail = (e) => {
    var email = e.target.value
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email != "" && regex.test(email) === false) {
      setEmailError('Enter valid Email!')
    } else {
      setEmailError('')
    }
  }

  const validatePassword = (e) => {
    var pass = e.target.value;
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})");
    if (strongRegex.test(pass)) {
      setPwdError('');
    }
    else {
      if (pass.length == 0) {
        setPwdError('');
      }
      else setPwdError('Must contain at least one number and one uppercase and lowercase letter, and at least 8 to 15 characters')
    }
  }

  const handleSubmit = (FormValues) => {
    // e.preventDefault();
    // const token = captchaRef.current.getValue();
    // captchaRef.current.reset();
    // console.log ("recaptcha: ", captchaRef.validate );
    // setRecaptcha(true);
    if (!setCaptcha) {
      setLoading(true);
      setLoggedInState("logging in");

      login(FormValues, location.state ? "/overview" : "/").catch((Error) => {
        setLoading(false);
        notify(
          typeof Error === "string"
            ? Error
            : typeof Error === "undefined" || !Error.message
              ? "ra.auth.sign_in_error"
              : Error.message,
          {
            type: "warning",
            messageArgs: {
              _:
                typeof Error === "string"
                  ? Error
                  : Error && Error.message
                    ? Error.message
                    : undefined,
            },
          }
        );
      });
    }
    else {
      document.getElementById("rc-anchor-alert").innerHTML = "Please check the box above to proceed";
    }
  };

  useEffect(() => {
    if (location?.state == "SUCCESS" || location?.state == "REGISTER SUCCESS")
      setNotifyData({
        open: true,
        message:
          location?.state == "REGISTER SUCCESS"
            ? "USER REGISTERED SUCCESSFULLY!"
            : "PASSWORD RESET SUCCESSFULLY !",
        severity: "success",
        duration: 4000,
      });
    let isAuth = localStorage.getItem("authToken");
    let userauth = localStorage.getItem('auth')
    if (isAuth && isAuth !== "undefined" && userauth !== null) navigate("/overview");


  }, []);



  // function onChange(value) {
  //   setDisabled(false)
  //   if(value == null){
  //     setDisabled(true)
  //   }
  // }


  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            flexGrow: 1,
            // background:'url(logo-bg.png)',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
          }}
          className="login-sec"
        >
          {loading ? <Spinner /> : ""}
          <Grid container spacing={2} className="bg-image">
            <Grid item xs={7}>
              {/* <Card
                sx={{ height: "60vh", marginTop: "6em", boxShadow: "none" }}
                className="bg-image"
              ></Card> */}
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{
                  minWidth: 300,
                  padding: "18px 42px !important",
                }}
              >
                <Box
                  sx={{
                    margin: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} className="logo-box">
                      <img src={TransportLogo} />
                      <Typography variant="h5" component="h2" align="center">
                        Vehicle Tracking System
                      </Typography>
                      <Typography variant="p" component="p" align="center">
                        Transport Department, Chhattisgarh
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Box>
                <Box sx={{ padding: "0 1em 1em 1em" }}>
                  <Box sx={{ marginTop: "2em", paddingTop: "10px" }}>
                    <TextInput
                      placeholder="Enter Email Address"
                      source="username"
                      label={translate("Email Address")}
                      disabled={loading}
                      validate={required()}
                      // validate={validateEmail}
                      onChange={validateEmail}
                      inputProps={{ maxLength: 50, autocomplete: 'new-password', form: { autocomplete: "off" } }}
                      fullWidth
                    />
                    {emailError === '' ? null :
                      <span style={errorstyle}>{emailError}</span>
                    }
                  </Box>
                  <Box sx={{ marginTop: "1em", display: "flex", position: "relative" }}>
                    <TextInput
                      source="password"
                      placeholder="Password"
                      label={translate("Password")}
                      type={showPassword ? "text" : "password"}
                      disabled={loading}
                      validate={required()}
                      onChange={validatePassword}
                      inputProps={{ maxLength: 15 }}
                      fullWidth
                    />
                    {showPassword ? (
                      <EyeIcon
                        onClick={togglePassword}
                        sx={{
                          position: "absolute",
                          right: "0",
                          top: "18px",
                          marginRight: "10px",
                          fontSize: "18px",
                          cursor: "pointer"
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={togglePassword}
                        sx={{
                          position: "absolute",
                          right: "0",
                          top: "18px",
                          marginRight: "10px",
                          fontSize: "18px",
                          cursor: "pointer"
                        }}
                      />
                    )}

                  </Box>
                  {pwdError === '' ? null :
                    <span style={errorstyle}>{pwdError}</span>
                  }
                  <Link to={`/forgot-password`} sx={{ top: "-2px !important" }}>Forgot Password?</Link>
                  {/* <div
                    className="g-recaptcha"
                    data-sitekey="6Ldbdg0TAAAAAI7KAf72Q6uagbWzWecTeBWmrCpJ"
                  ></div> */}
                  <Reaptcha
                    sitekey={REACT_APP_SITE_KEY}
                    //ref={captchaRef}
                    // onChange={onChange}
                    // id="recaptchaId"
                    // size="normal"
                    onVerify={() => setRecaptcha(false)}
                  />
                  {setCaptcha &&
                    <div id="rc-anchor-alert" class="rc-anchor-alert"></div>
                  }
                </Box>
                <CardActions sx={{ padding: "0 1em 1em 1em" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    //disabled={loading}
                    // disabled={setCaptcha}
                    // disabled={loading || disabled}
                    fullWidth
                  // className={
                  //   disabled
                  //     ? "layout-disable"
                  //     : ""
                  // }
                  >
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CardActions>
                <p className="signup-p">
                  Not registered yet ?{" "}
                  <Link to={`/new-register`}>Register now </Link>
                </p>
                <CardActions sx={{ padding: "0 1em 1em 1em", marginTop: "1em" }}>
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    className="search-vehicle-btn"
                    onClick={()=>{navigate(`/search-vehicle`)}}
                  >
                    Search Vehicle
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Box>
      </Form>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;

// interface FormValues {
//     username?: string;
//     password?: string;
// }
// const  FormValues = {
//     username: string,
//     password: string
// }
