import { useContext, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import urls from '../constants';
import { setCookie } from '../utils/cookies';
import { PositionContext } from '../utils/PositionContext';
import { exists } from '../utils/validate';
import useThrottle from '../hooks/useThrottle';

function useNotification() {
  //Connect to remote websocket and wait for messages

  //const LOCAL_STORAGE_KEY = "DeviceLog"
  let wsUrl = urls.NOTIFICATION_WEBSOCKET_URL.replace(/http/, 'ws');

  const [socketUrl, setSocketUrl] = useState(wsUrl);
  const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl);
  const [notification, setNotification] = useState([]);
  const [notificationerr, setNotificationerr] = useState({});
  //const throttledPositions = useThrottle(positions, 100);

   // WebSocket response data
 //   {
 //     "id": 550931,
 //     "attributes": {},
 //     "deviceId": 156,
 //     "type": "deviceOffline",
 //     "eventTime": "2022-09-15T23:01:45.000+00:00",
 //     "positionId": 7739300,
 //     "geofenceId": 0,
 //     "maintenanceId": 0,
 //     "action": null,
 //     "remark": null,
 //     "status": false,
 //     "location": null,
 //     "latitude": 21.7343533,
 //     "longitude": 81.5357616
 // }

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  //If new message arrives the side effect gets called
  useEffect(() => {
    if (exists(lastMessage)) {
      let obj = JSON.parse(lastMessage.data);
      if (exists(obj) && Array.isArray(obj.events)) {
        setNotification(obj?.events)
         // console.log("WebSOcketobj",obj?.events)
        //console.log("Position of ID " + obj.positions[0].deviceId + ': ' + obj.positions[0].latitude + ':' + obj.positions[0].longitude);
        // if(JSON.stringify(obj.positions) !== JSON.stringify(positions)) {
       // setDeviceLog(prev => prev.concat(obj.deviceLog))

        // }
      }
    } else {
      console.log("Websocket " + socketUrl + " in state:" + connectionStatus);
      if (connectionStatus === "Open" || connectionStatus === "Connecting") {
        setNotificationerr({ errmessage: "Connecting with device ..." })
      }
      else {
        setNotificationerr({ errmessage: "Websocket connection is " + connectionStatus })
      }
    }
    //  return(()=>{

    // });
  }, [lastMessage, socketUrl]);



  return [notificationerr, notification, setNotification, setSocketUrl];
}

export default useNotification;