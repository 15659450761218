import { useEffect, useState } from "react";
import { initialize, MMI_KEY } from './../utils/initializeMap'
import { useGetOne, Button } from "react-admin";
import Grid from '@mui/material/Grid';
import vtsDataProvider,{getMMIToken} from './../providers/dataProvider';
import CloseIcon from '@mui/icons-material/Close';
import { secondsToHms } from "./../utils/time";
import { findAllByTestId } from '@testing-library/react';
import Trailchart from './Trailchart'
import AudioPlayer from './AudioPlayer';
import tracks from './tracks';
import PauseIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import ForwardIcon from '@mui/icons-material/LastPageOutlined';
import BackwardIcon from '@mui/icons-material/FirstPageOutlined';
import TrainIcon from '@mui/icons-material/TrainOutlined';
import GpsIcon from '@mui/icons-material/MyLocationOutlined';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import {
  Box, Typography,
  Card
} from '@mui/material';

// import RTaxiicon from '../../src/images/rc.png';
// import GRTaxiicon from '../../src/images/gc.png';
// import moment from 'moment';
// import DivIconExt from '../utils/divIcon';
// import MarkerExt from '../utils/marker';

// L.Marker.prototype.options.icon = L.icon({
//   iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"
// });

// let TaxiIcon = new DivIconExt({
//   iconUrl: RTaxiicon,
//   iconSize: [25, 41],
// });

// let GRTaxiIcon = new DivIconExt({
//   iconUrl: GRTaxiicon,
//   iconSize: [25, 41],
// });

// let StartIcon = L.icon({
//   iconUrl: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
//   iconSize: [25, 41],
// });
// let EndIcon = L.icon({
//   iconUrl: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
//   iconSize: [25, 41],
// });

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


export default function TripsTrailRouting({ playRoutesData }) {
  const [plan_Route, setPlanRoute] = useState([]);
  const [actual_Route, setActualRoute] = useState([]);
  const [routeData, setRouteData] = useState([]);
  const [stop_Points, setStopPoints] = useState([]);
  const [layerstyle, setLayerStyle] = useState("standard-day");
  const [mapObject, setMapObject] = useState(null);
  const [carpolyline, setCarpolyline] = useState(null);
  const [planRoute, setplanRouteDetails] = useState(null);
  const [actualRoutedetails, setActualRouteDetails] = useState(null);
  const [token, setToken] = useState({})

  const styleMap = { width: '100%', height: '69vh', display: 'inline-block' }
  var mapObj;
  var polyline;
  var polyline1;

  // const { data: planRoute, isLoading, error } = useGetOne(
  //   'routeassign',
  //   { id: playRoutesData.tripId },
  //   { enabled: (playRoutesData.tripId !== 0 && playRoutesData.tripId !== undefined) }
  // );

  useEffect(()=>{
    getMMIToken(setToken);
  },[])

  useEffect(() => {
    initialize(token?.access_token, () => {
      //Action after script has been loaded completely
      afterScriptsLoaded();
    })
  }, [token])

  // useEffect(() => {

  //   // if (plan_Route && actual_Route) {
  //   //   if (plan_Route.length > 0 && actual_Route.length > 0) {
  //   afterScriptsLoaded();
  //   //   }
  //   // }

  // }, [plan_Route, actual_Route, stop_Points, layerstyle])

  useEffect(() => {
    const getPlanRoute = async () => {
      if (playRoutesData) {

        const reportrouteparams1 = {
          filter:
          {
            deviceId: playRoutesData.deviceId,
            from: playRoutesData.startTime,
            to: playRoutesData.endTime
          }
        }
        const { data: reportsroutedata } = await vtsDataProvider.getList("reportsroute", reportrouteparams1)

        setActualRouteDetails(reportsroutedata);
        const { data: planRoutedetails } = await vtsDataProvider.getOne("routeassign", { id: playRoutesData.id })
        setplanRouteDetails(planRoutedetails)

        const reportrouteparams = {
          filter:
          {
            deviceId: playRoutesData.deviceId,
            from: playRoutesData.startTime,
            to: playRoutesData.endTime
          }
        }

        const { data: stopPoints } = await vtsDataProvider.getList("trailsstoppoints", reportrouteparams)
        setStopPoints(stopPoints);

        let coordarray1 = [];
        let arrrouteData = [];
        reportsroutedata?.map((route, index) => {
          coordarray1.push({ lat: route.latitude, lng: route.longitude })

          arrrouteData.push({
          deviceTime: route.deviceTime,
          latitude: route.latitude,
          longitude: route.longitude,
          speed: route.speed,
          course: route.course
          });

        })

        setRouteData(arrrouteData)
        setActualRoute(coordarray1)

      }

    }
    getPlanRoute();

    return () => {
    }

  }, [playRoutesData])

  useEffect(() => {

    const getTrails = async () => {

      if (planRoute) {
        let pointsarr = [];

        // planRoute?.route?.legs?.map((pRoute, index) => {

        //   if (index === 0) {
        //     pointsarr.push({lat: pRoute.latitudeFrom, lng: pRoute.longitudeFrom})
        //     pointsarr.push({lat: pRoute.latitudeTo, lng: pRoute.longitudeTo})
        //   }
        //   else {
        //     pointsarr.push({lat: pRoute.latitudeTo, lng: pRoute.longitudeTo})
        //   }
        // })
        // setPlanRoute(pointsarr)

        planRoute?.route?.legs?.map((pRoute, index) => {
          let area = pRoute.polyline;
          let startpoint = area.indexOf("(");
          let endpoint = area.indexOf(")");

          let latlngs = area.substring(startpoint + 1, endpoint).trim();
          const latlngarray = latlngs.split(",");
          latlngarray.map((pos) => {
            const posarray = pos.split(" ");
            let latitude = posarray[0] === "" ? posarray[1] : posarray[0];
            let longitude = posarray[0] === "" ? posarray[2] : posarray[1];
            pointsarr.push({ lat: latitude, lng: longitude })
          })

        })
        setPlanRoute(pointsarr)
      }


    }
    getTrails();

    return () => {
    }

  }, [planRoute]);


  function afterScriptsLoaded() {
    mapObj = window.mappls.Map('map', {
      center: [21.35516, 81.28199],
      zoom: 7,
      zoomControl: true,
    })
    setMapObject(mapObj)
  }


  useEffect(() => {
    if (mapObject) {



      window.mappls.setStyle(layerstyle)
      // mapObject.addListener('load', function () {
      if (plan_Route && actual_Route && routeData) {
        if (plan_Route.length > 0 && actual_Route.length > 0 && routeData.length>0) {

          window.mappls.Marker({
            map: mapObject,
            position: plan_Route[0],
            icon_url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            fitbounds: true,
          });


          window.mappls.Marker({
            map: mapObject,
            position: plan_Route[(plan_Route.length * 1) - 1],
            fitbounds: true,
            icon_url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
          });

          window.mappls.Marker({
            map: mapObject,
            position: actual_Route[0],
            fitbounds: true,
            icon_url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
          });


          window.mappls.Marker({
            map: mapObject,
            position: actual_Route[(actual_Route.length * 1) - 1],
            fitbounds: true,
            icon_url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
          });


          if (stop_Points) {
            if (stop_Points.length > 0) {
              stop_Points.map((stops, index) => {

                let alarmtype = ""
                if(stops.type === "alarm")alarmtype = " ("+ stops?.attributes?.alarm + " )"

                window.mappls.Marker({
                  map: mapObject,
                  position: { lat: stops.latitude, lng: stops.longitude },
                  popupHtml: "<div><b>Type</b> : " + stops.type +  alarmtype +"</div><div><b>Event Time</b> : " + moment(stops.eventTime).format("DD MMM YYYY hh:mm:ss") + "</div><div><b>coordinate's </b> : (" + stops.latitude + " , " +stops.longitude+")</div>",
                  icon_url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
                });
              })

            }
          }


          polyline = window.mappls.Polyline({
            map: mapObject,
            paths: plan_Route,
            strokeColor: 'green',
            strokeOpacity: 1.0,
            strokeWeight: 3,
            fitbounds: true
          });

          mapObject.addListener('load', function () {

            var tracking_option = {
              map: mapObject,
              data: routeData,
              routeColor:"red",
              strokeWidth: 3,
              ccpIcon:'https://jmcweblink.blob.core.windows.net/jmcfilelink/images/vts/Car.png',
              ccpIconWidth: 40,
              fitBounds: true,
              speedText: true,
             
      /* className:"slid",
              position:"bottom-left" // default top-let*/
  }
         var tracking_plugin = window.mappls.trackingTimeline(tracking_option);



            // polyline1 = window.mappls.Polyline({
            //   map: mapObject,
            //   paths: actual_Route,
            //   strokeColor: 'red',
            //   strokeOpacity: 1.0,
            //   strokeWeight: 3,
            //   fitbounds: true,
            //   animate: {
            //     speed: 15,
            //     icon_width: 20,
            //     icon_height: 50,
            //     icon_url: "http://www.mapmyindia.com/api/advanced-maps/doc/sample/map_sdk/car.png",
            //     repeat: true,
            //   },
            // });

            // setCarpolyline(polyline1)

          })


        }
      }
      //  });
    }
  }, [mapObject, plan_Route, actual_Route,routeData])

  useEffect(() => {
    if(mapObject)
    {
      window.mappls.setStyle(layerstyle);
    }
  }, [layerstyle])

  useEffect(() => {
    if (mapObject) {
      loadDataOnlyOnce();
      clickLayer();
    }
  }, [mapObject])

  function clickLayer() {
    // if(mapObject){
    document.getElementById('map_default').onclick = function (e) {
      setLayers('standard-day')
    }
    document.getElementById('grey-day').onclick = function (e) {
      setLayers('grey-day')
    }
    document.getElementById('standard-night').onclick = function (e) {
      setLayers('standard-night')
    }
     document.getElementById('map_hybrid').onclick = function(e) {
      setLayers('standard-hybrid')
    }
  }

  const msToTime = (duration) => {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;

    // + "." + milliseconds;
  }

  const loadDataOnlyOnce = () => {

    //  mapObject.addListener('load', function() {
    //  var container = document.getElementById('mmi_ctrl1')
    var container = document.querySelector(".expand-map-control")
    var layer_id = document.getElementById('layerId')
    if (!layer_id)
      // container.innerHTML +='<div id="layerId"><button name="layerctrl" >Layer</button></div>'
      container.innerHTML += '<li id="myBtn" class="map-layers"><a style="height: 36px;line-height: 32px;" title=" Map Layers" nofollow="" > <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LayersIcon"><path d="m11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"></path></svg></a>\
            <ul class="clearfix map-layer" id="map-layer" style="display:none">\
            <span class="" id="layer-close"> X </span>\
            <li id="map_default" class="dyLayerMap">\
                <a href="javascript:void(0)" class="mapStyleBlock active">\
                  <span class="layer-item-img">\
                      <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" />\
                      <img src="https://apis.mapmyindia.com/vector_map/thumb/style.jpg" alt="" class="mode-img" />\
                  </span>\
                  <p>Default Map </p>\
                </a>\
            </li><li id="grey-day" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock">\
                <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                  <img src="https://www.mappls.com/images/gray.jpg" alt="" class="mode-img" style={{width: "82px",height: "56px",right: 0,bottom: 0}} />\
                </span>\
                <p>Grey Mode </p>\
            </a>\
          </li><li id="standard-night" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock" >\
                <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                  <img src="https://www.mappls.com/images/nightmode.jpg" alt="" class="mode-img" />\
                </span>\
                <p>Night Mode </p>\
            </a></li>\
            <li id="map_hybrid" class="dyLayerMap">\
            <a href="javascript:void(0)" class="mapStyleBlock" >\
               <span class="layer-item-img">\
                  <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                  <img src="https://www.mappls.com/images/maplayer/hy_map.png" alt="" class="mode-img" />\
               </span>\
               <p>Satellite </p>\
            </a></li>\
          </ul>\
        </li>'

    // };

  };

  const setLayers = (id) => {
    setLayerStyle(id)
    //window.mappls.setStyle(id)
  }

  const Dateformat = (source) => {
    const dt = (source !== null) ? moment(source).format("DD MMM YYYY hh:mm:ss") : "";
    return dt;
  };

  return (
    <div>
      <div id="map" style={styleMap}></div>
      {playRoutesData && <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', position: 'relative', marginTop: '-200px' }}>
        <Card
          sx={{
            minHeight: 52,
            minWidth: 250,
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            '& a': {
              textDecoration: 'none',
              color: 'inherit',
            },
          }}
        >
          <Box sx={{ padding: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={4} sx={{ borderRight: '2px solid #eee' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: "10px" }}>
                  <Button
                    variant="contained" className='Card-Icons' sx={{
                      borderColor: 'blue', marginTop: '0!important', marginRight: '10px!important'
                    }}
                    startIcon={<TrainIcon sx={{ fill: "#252F3F !important;" }} />}></Button>
                  <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                    sx={{
                      marginBottom: '15px',
                    }}>{playRoutesData.deviceName}

                  </Typography>
                  <Button
                    variant="contained" className='Card-Icons two' sx={{
                      borderColor: 'blue', marginTop: '0!important', marginRight: '10px!important', marginLeft: '10px', border: 'none !important'
                    }}
                    startIcon={<GpsIcon sx={{ fill: "#4646F2 !important" }} />}></Button>
                </Box>
                <div className="left-playtrail">
                  <ul class="progress">

                    <li class="progress__item progress__item--completed">
                      <p class="progress__title">{Dateformat(playRoutesData.startTime)}</p>
                      <p class="progress__info">{playRoutesData?.tripLegs[0]?.addressFrom}</p>
                    </li>
                    <li class="progress__item progress__item--active">
                      <p class="progress__title">{Dateformat(playRoutesData.endTime)}</p>
                      <p class="progress__info">{playRoutesData?.tripLegs[(playRoutesData?.tripLegs?.length) - 1]?.addressTo}</p>
                    </li>
                  </ul>
                  <Box className="trail-playback">
                    <Box sx={{ display: 'flex', padding: "10px" }}>
                      <Typography color="textSecondary" variant="h" component="h4"
                        sx={{ fontSize: '14px', width: "46px" }}>Duration
                      </Typography>
                      <Typography color="textSecondary" variant="h" component="p"
                        sx={{
                          marginLeft: '53px'
                        }}>{msToTime(playRoutesData?.tripLegs[0]?.durationTraveled)}
                        <p style={{ fontSize: "12px", fontWeight: '500', margin: '0' }}>Hrs</p>
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', padding: "10px" }}>
                      <Typography color="textSecondary" variant="h" component="h4"
                        sx={{ fontSize: '14px', width: "46px" }}>Distance
                      </Typography>
                      <Typography color="textSecondary" variant="h" component="p"
                        sx={{
                          marginLeft: '53px'
                        }}>{playRoutesData?.tripLegs[0]?.distanceTraveled !== undefined ? (playRoutesData?.tripLegs[0]?.distanceTraveled).toFixed(2) : 0}
                        <p style={{ fontSize: "12px", fontWeight: '500', margin: '0' }}>Kms</p>
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Grid>
              {/* <Grid item xs={2} sx={{ borderRight: '2px solid #eee', paddingLeft: "0 !important" }} className="trail-playback"> */}

              {/* <Box sx={{ display: 'flex', padding: "10px" }}>
                                    <Typography color="textSecondary" variant="h" component="p"
                                        sx={{ fontSize: '14px', width: "46px" }}>Time
                                    </Typography>
                                    <Typography color="textSecondary" variant="h" component="h4"
                                        sx={{
                                            marginLeft: '53px'
                                        }}>{msToTime(playRoutesData.duration)}
                                        <p style={{ fontSize: "12px", fontWeight: '500', margin: '0' }}>Hrs mins</p>
                                    </Typography>
                                </Box> */}
              {/* </Grid> */}
              <Grid item xs={8}>
                <Box className="trail-linechart">
                  {/* <LineChart width={480} height={200} data={data}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line type="monotone" dataKey="Ignition" stroke="#6E26FA" />
                                                <Line type="monotone" dataKey="Speed" stroke="#8AC37D" />
                                            </LineChart> */}
                  <Trailchart reportsRoute={actualRoutedetails} />
                  {/* <AudioPlayer tracks={tracks} carpolyline={carpolyline} mapObject={mapObject} /> */}

                </Box>
                {/* <Box className="audio-controls">
                                             <BackwardIcon />
                                            <PauseIcon />
                                            <ForwardIcon /> 
                                            <Button label="1x" />
                                            <BorderLinearProgress variant="determinate" value={50} />
                                        </Box> */}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
      }
    </div>
  );
}
