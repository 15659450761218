import React, { useState, useEffect, useRef, useContext } from "react";
import {
  List,
  TextField,
  DateTimeInput,
  Datagrid,
  SelectInput,
  TextInput,
  Pagination,
  ListToolbar,
  useGetList,
  ListContextProvider,
  Button,
  useListController,
  useResourceContext,
  RefreshButton,
  useRefresh,
  useRecordContext,
  LinearProgress,
} from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { Grid, Card } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./overview.css";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MovingVehicle from "./MovingVehicle";
import OngoingTrips from "./ongoingtrips";
import PanicStatus from "./panicStatus";
import ChartView from "../../components/distanceChart";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CommunicatingChart from "../../components/communicatingChart";
import PieChartView from "../../components/pieChart";
import urls from "../../constants";
import httpClient from "../../utils/httpclient";
import { stringify } from "query-string";
import { now, oneDayAgo, oneMonthAgo } from "../../utils/time";
import {
  StatisticContext,
  StatisticContextProvider,
} from "../../utils/statisticContext";
import IdleVehicle from "./IdleVehicle";
import StoppedVehicle from "./StoppedVehicle";
import OtherVehicle from "./OtherVehicle";
import InactiveVehicle from "./InactiveVehicle";
import VTSListToolbar from "../../components/VTSListToolbar";
import VehicleIconField from "../../components/vehicleIconField";
import { VehicleSummary } from "../../components/Vehiclesummary";
import { TitleContext } from "../../utils/TitleContext";
import { fetchGroupList } from "../../providers/dataProvider";
import { GroupContext } from "../../utils/GroupContext";
import useTabPermissions from "../../hooks/useTabPermissions";
import moment from "moment";
import DatePicker from "react-datepicker";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import { AutocompleteInput } from "react-admin";
import { default as ReactSelect } from "react-select";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};
const Buttonstyle = {
  position: "absolute",
  background: "blue",
  color: "#fff",
  zIndex: 1,
  textTransform: "capitalize",
  width: "150px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  name: "",
  phone: "",
  email: "",
  password: "",
};

const position = [51.505, -0.09];

const TabPanel = (props) => {
  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && <Typography>{children}</Typography>}
    </React.Fragment>
  );
};

export const Dashboard = () => {
  const [value, setValue] = React.useState(0);
  const { setTitle } = React.useContext(TitleContext);
  var tot = 0;
  const perPage = 10;
  const [filter, setFilter] = useState("");
  const { groupList } = React.useContext(GroupContext);
  const [selectDate, setSelectDate] = useState([]);
  const [groupdrop, setgroupdrop] = useState([]);
  const datePickerRef = useRef(null);
  const [groupId, setGroupId] = useState();

  var date = new Date();
  var formatedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  var placeholderDate = StaringDate + " - " + formatedDate;

  let todate = new Date();
  let prvDate = new Date(
    todate.getFullYear(),
    todate.getMonth() - 1,
    todate.getDate()
  );
  const [dateRange, setDateRange] = React.useState([prvDate, todate]);
  const [startDate, endDate] = dateRange;

  const refresh = useRefresh();

  const fetchData = () => {
    refresh();
  };

  useEffect(() => {
    setTitle("Dashboard");
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  const tabView = useTabPermissions("dashboard");

  const { data: overview } = useGetList("overview");
  let overviewData = overview;

  if (overview?.length > 0) tot = overview[0]["total_devices"];

  const { statistic, setStatistic } = useContext(StatisticContext);

  // useEffect(() => {
  //   const getStatistics = async (resource, params) => {
  //     let url = urls.STATISTICS_URL;
  //     const query = { from: oneMonthAgo(), to: now() };
  //     url = `${url}?${stringify(query)}`;
  //     return await httpClient(url).then(({ headers, json }) => {
  //       setStatistic(json);
  //     });
  //   };
  //   getStatistics();
  // }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const sort = { field: "id", order: "ASC" };

  // const { data, isLoading, page, setPage, setPerPage, total, ...listContext } =
  //   useListController({
  //     resource: "vehiclesummary",
  //     filter: { group_id: groupId?.value, date: selectDate },
  //   });

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const fetchGroupData = async () => {
    const res = await fetchGroupList("groupsdata");
    const list = formatDevices(res);
    setgroupdrop(list);
  };

  useEffect(() => {
    fetchGroupData();
  }, []);

  const formatDevices = (list) => {
    var te = list?.map((data) => ({
      // label: (data?.id).toString() + " - " + data?.name.toString(),
      value: data?.id,
      label: data?.name,
    }));
    return te;
  };

  const handlegroupChange = (selectedOption) => {
    setGroupId(selectedOption);
  };

  const handleUpdateDate = (update) => {
    setDateRange(update);
  };

  const handleDatePicker = () => {
    const dp = datePickerRef.current;
    dp.setOpen(true);
  };

  useEffect(() => {
    dateRange[0] && dateRange[1] && setSelectDate(dateRange);
  }, [dateRange]);

  const filters = [
    <Box className="sel-group" alwaysOn>
      <ReactSelect
        name="groupId"
        source="group_id"
        options={groupdrop}
        placeholder="Select group"
        onChange={handlegroupChange}
        value={groupId}
      />
    </Box>,
    <Box className="date-range" alwaysOn>
      <FormControl>
        <DatePicker
          name="date"
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => handleUpdateDate(update)}
          placeholderText={placeholderDate}
          isClearable={true}
          ref={datePickerRef}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
        />
        <IconButton className="cal-icon" onClick={handleDatePicker}>
          <CalendarIcon />
        </IconButton>
      </FormControl>
    </Box>,
    <TextInput label="Search Vehicle No" source="q" alwaysOn />,
  ];

  const filterValues = { q: filter };
  const setFilters = (filters) => setFilter(filters.q);

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  const VehicleNameField = ({ source }) => {
    const record = useRecordContext();
    return (
      <span sx={{ fill: "black", fontSize: "12px", marginRight: "2px" }}>
        {record ? record[source] : ""}
        <Button
          variant="contained"
          className="Card-Icons"
          sx={{
            border: "none !important",
          }}
          // startIcon={<Icon6 className="icon-css" />}
        >
          {/* <img src={ViewmapIcon} /> */}
        </Button>
      </span>
    );
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] !== null
        ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss")
        : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs value={value} onChange={handleChange} className="tab-top">
        {tabView && tabView?.viewOverall && <Tab label="Overall" />}
        {/* {tabView && tabView?.viewVehicleSummary && (
          <Tab label="Vehicle Summary" />
        )} */}
        <Box textAlign="center" className="right-text">
          <Typography color="textSecondary" component="h2">
            {tot}
          </Typography>
          <Typography color="textSecondary" component="p">
            Total
          </Typography>
        </Box>
      </Tabs>

      <TabPanel value={value} index={0}>
        {tabView && tabView?.readOverall && (
          <Box className="tab-content">
            <div>
              <div style={styles.flex}>
                <MovingVehicle overviewData={overviewData} />
                <IdleVehicle overviewData={overviewData} />
                <StoppedVehicle overviewData={overviewData} />
                <OtherVehicle overviewData={overviewData} />
                <InactiveVehicle overviewData={overviewData} />
              </div>
            </div>
            <div style={styles.flex} className="left-chart">
              <div style={styles.leftCol}>
                {/* <div style={styles.flex}>
                <OngoingTrips />
              </div> */}
                <div style={styles.flex}>
                  <ChartView />
                </div>
              </div>

              <div style={styles.rightCol}>
                <div style={styles.singleCol}>
                  <PanicStatus />
                </div>
                <div style={styles.singleCol} className="left-chart">
                  <PieChartView />
                </div>
              </div>
            </div>
            <div style={styles.flex} className="left-chart">
              <div style={styles.leftCol}>
                {/* <div style={styles.flex}>
                <ChartView />
              </div> */}
                <div style={styles.flex}>
                  <OngoingTrips />
                </div>
              </div>

              <div style={styles.rightCol}>
                {/* <div style={styles.singleCol}> */}
                <CommunicatingChart />
                {/* </div> */}
              </div>
            </div>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* {tabView && tabView?.readVehicleSummary && (
          <>
            {isLoading ? (
              <>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              </>
            ) : (
              <>
                {" "}
                <ListContextProvider
                  value={{
                    data,
                    filterValues,
                    page,
                    setFilters,
                    setPage,
                    setPerPage,
                    total,
                    ...listContext,
                  }}
                >
                  <VTSListToolbar
                    filters={filters}
                    className="summary-toolbar"
                    setGroupId={setGroupId}
                    type="overview"
                  />
                  {data === undefined ||
                  (Array.isArray(data) === true && data.length === 0) ? (
                    <>
                      <div className="no-records">No Records</div>
                    </>
                  ) : (
                    <>
                      <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
                      <Box className="list-content">
                        <Datagrid
                          bulkActionButtons={false}
                          className="table-grid"
                          empty={<CustomEmpty />}
                        >
                          <VehicleNameField
                            label="Vehicle#"
                            source="deviceName"
                          />
                          <DateSetter label="Start Date" source="startTime" />
                          <TextField label="Distance" source="distance" />
                          <TextField
                            label="Odometer start"
                            source="startOdometer"
                          />
                          <TextField
                            label="Odometer end"
                            source="endOdometer"
                          />
                          <TextField label="Avg Speed" source="averageSpeed" />
                          <TextField label="Max Speed" source="maxSpeed" />
                          <TextField label="Engine(hrs)" source="engineHours" />
                          <TextField label="Spent fuel" source="spentFuel" />
                        </Datagrid>
                      </Box>
                    </>
                  )}
                </ListContextProvider>
              </>
            )}
          </>
        )} */}
      </TabPanel>
    </Box>
  );
};
