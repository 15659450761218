import { useContext, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import urls from '../constants';
import { setCookie } from '../utils/cookies';
import { PositionContext } from '../utils/PositionContext';
import { exists } from '../utils/validate';
import useThrottle from '../hooks/useThrottle';

function useDeviceLog() {
  //Connect to remote websocket and wait for messages

  const LOCAL_STORAGE_KEY = "DeviceLog"
  let wsUrl = urls.DEVICE_WEBSOCKET.replace(/http/, 'ws');

  const [socketUrl, setSocketUrl] = useState(wsUrl + "?deviceId=0");
  const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl);
  const [devicelog, setDeviceLog] = useState([]);
  const [devicelogerr, setDeviceLogerr] = useState({});
  //const throttledPositions = useThrottle(positions, 100);


  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  //If new message arrives the side effect gets called
  useEffect(() => {
    if (exists(lastMessage)) {
      let obj = JSON.parse(lastMessage.data);
      if (exists(obj) && Array.isArray(obj.deviceLog)) {
        //  console.log("obj",obj)
        //console.log("Position of ID " + obj.positions[0].deviceId + ': ' + obj.positions[0].latitude + ':' + obj.positions[0].longitude);
        // if(JSON.stringify(obj.positions) !== JSON.stringify(positions)) {
        setDeviceLog(prev => prev.concat(obj.deviceLog))

        // }
      }
    } else {
      console.log("Websocket " + socketUrl + " in state:" + connectionStatus);
      if (connectionStatus === "Open" || connectionStatus === "Connecting") {
        setDeviceLogerr({ errmessage: "Connecting with device ... Loading data" })
      }
      else {
        setDeviceLogerr({ errmessage: "Websocket connection is " + connectionStatus })
      }
    }
    //  return(()=>{

    // });
  }, [lastMessage, socketUrl]);


  useEffect(() => {
    // console.log("devicelog",devicelog)
    // console.log("devicelog string",JSON.stringify(devicelog))
    // localStorage.setItem(LOCAL_STORAGE_KEY,JSON.stringify(devicelog))
    if (devicelog?.length > 100) {
      getWebSocket.close();
    }
  }, [devicelog])

  return [devicelogerr, devicelog, setDeviceLog, setSocketUrl];
}

export default useDeviceLog;