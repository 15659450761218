import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreHorizOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { Button, useGetList, useRedirect } from "react-admin";
import { Link } from "react-router-dom";

const COLORS = ['#55B9ED', '#B894FB', '#1F78B4', '#F0AB5B', '#FA9F9F'];

export default function PieChartView() {
  const [alarmStatusData, setAlarmStatusData] = useState([]);
  var sum = 0;

  const { data: alarmstatus, isLoading } = useGetList("alarmstatus");

  const formatData = (alarmstatus) => {
    let chartData = alarmstatus[0];
    let testData = [];
    Object.entries(chartData).forEach(([key, value]) => {
      if(key == 'sos'){
        testData[0] = {
          name: key,
          value: value,
        }}
      else if(key == 'geofence'){
        testData[1] = {
          name: key,
          value: value,
        }}
      else if(key == 'geofenceEnter'){
        testData[2] = {
          name: key,
          value: value,
        }}
      else if(key == 'geofenceExit'){
        testData[3] = {
          name: key,
          value: value,
        }}
      else if(key == 'overspeed'){
        testData[4] = {
          name: key,
          value: value,
        }}
    });
    testData?.map((obj, index) => {
      sum = sum + obj?.value;
    });
    setAlarmStatusData(testData);
  };

  useEffect(() => {
    if (!alarmstatus) return;
    alarmstatus?.length > 0 && formatData(alarmstatus);
  }, [alarmstatus]);

  const initialData = [{ name: "test", value: 1 }];

  return (
    <Paper
      style={{marginTop: "15px" }}
      className="pie-chart-view"
    >
      <div className="pie-right">
        <Typography
          color="textSecondary"
          variant="h"
          component="h4"
          className="Card-Title"
          sx={{
            marginBottom: "15px",
          }}
        >
          Today's Alarm
        </Typography>

        {/* <MoreIcon style={{ float: "right" }} /> */}
      </div>

      <div style={{ display: "flex" }}>
        <PieChart width={220} height={360}>
          {(isLoading || sum == 0) && (
            <Pie
              data={initialData}
              cx={120}
              cy={200}
              innerRadius={68}
              outerRadius={80}
              fill="#dadada"
              stroke="none"
              paddingAngle={0}
              dataKey="value"
            ></Pie>
          )}
          {alarmStatusData?.length > 0 ? (
            <Pie
              data={alarmStatusData}
              cx={120}
              cy={200}
              innerRadius={68}
              outerRadius={80}
              fill="#8884d8"
              stroke="none"
              paddingAngle={0}
              dataKey="value"
            >
              {alarmStatusData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          ) : (
            false
          )}
        </PieChart>

        <div className="pie-wrap-det">
          <div style={{ display: "flex", marginTop: "15px" }}>
            <Typography color="textSecondary" className="lightb-color">
              <CircleIcon /> Sos
              <span>({alarmstatus ? alarmstatus[0]?.sos : 0})</span>
            </Typography>
            <Typography color="textSecondary" className="violet-color">
              <CircleIcon /> Geofence
              <span>({alarmstatus ? alarmstatus[0]?.geofence : 0})</span>
            </Typography>
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <Typography color="textSecondary" className="navyb-color">
              <CircleIcon /> Geofence Enter
              <span>({alarmstatus ? alarmstatus[0]?.geofenceEnter : 0})</span>
            </Typography>
            <Typography color="textSecondary" className="orange-color">
              <CircleIcon /> Geofence Exit
              <span>({alarmstatus ? alarmstatus[0]?.geofenceExit : 0})</span>
            </Typography>
          </div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <Typography color="textSecondary" className="pink-color">
              <CircleIcon /> Overspeed{" "}
              <span>({alarmstatus ? alarmstatus[0]?.overspeed : 0})</span>
            </Typography>
          </div>
          <Link to="/alarm" state={{ status: "alarm" }}>
            <Button
              style={{ float: "right", marginTop: "20px" }}
              className="show-btn"
            >
              {" "}
              View all
            </Button>
          </Link>
        </div>
      </div>
    </Paper>
  );
}
