import * as React from "react";
import { Card, Box, Typography } from "@mui/material";
import { Button } from "react-admin";
import { Link } from "react-router-dom";
import Icon6 from "../../images/Viewmap.svg";
import FormatListIcon from "@mui/icons-material/FormatListBulletedOutlined";
import CircularProgressWithLabel from "./circularProgress";


const CardWithIcon = (props) => {
  const { title, subtitle, status, statusValues, children, to } = props;

  return (
    <Card
      sx={{
        minHeight: 52,
        maxWidth: "inherit",
        marginRight: "15px",
        marginTop: "15px",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
     
      <Box
        sx={{
          overflow: "inherit",
          padding: "16px",
          background: (theme) =>
            `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "inherit" : "#dc2440",
          },
        }}
      >
        {/* <Box width="3em" className="icon">
                        {createElement(icon, { fontSize: 'large' })}
                    </Box> */}
                    
        <Box textAlign="left" className="left-box">
          <Typography color="textSecondary" component="h2">
            {title}
          </Typography>
          <Typography variant="h5" component="h2">
            {subtitle}
          </Typography>
          <Typography variant="h5" component="p" sx={{ color: "#0ed30e" }} status={status}>
            {status}
          </Typography>
        </Box>
        <Box textAlign="right" className="right-box">
          <Box sx={{height:"24px"}}>
        {subtitle != 0 && <Link to={to} state={{ status: title, type: 'list'}}>
          <Button
            variant="contained"
            className="Card-Icons one"
            sx={{
              borderColor: "blue",
            }}
            startIcon={<FormatListIcon />}
          ></Button>
          </Link>}
          {subtitle != 0 && status !== "Inactive" && <Link to={to} state={{ status: title, type: 'map' }}>  
          <Button
            variant="contained"
            className="Card-Icons map"
            sx={{
              border: "none !important",
            }}
            // startIcon={<Icon6 />}
          >
             <img src={Icon6} />
          </Button>
          </Link>}
          </Box>
          <CircularProgressWithLabel
            title={title}
            statusValues={statusValues}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default CardWithIcon;
