import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Reaptcha from 'reaptcha';
import { Typography } from "@mui/material";
import { Button, Card, CardActions } from "@mui/material";
import TransportLogo from "../../images/transport_department_logo.jpeg";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "@mui/icons-material/ArrowCircleLeft";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Form,
    TextInput,
    useTranslate
} from "react-admin";
import urls from "../../constants";
import Box from "@mui/material/Box";
import { Spinner } from "../../components/spinner";
import SendNotification from "../../utils/sendNotification";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Container } from '@mui/system';
import moment from "moment";
import TextField from '@mui/material/TextField';


const REACT_APP_SITE_KEY = "6LeBirsjAAAAAAn_fua9ErE-O_0f4oVXbMMq3SV-";

const errorstyle = {
    color: "#d32f2f",
    fontSize: "16px",
    display: "block"
};

const SearchVehicle = () => {
    const [notifyData, setNotifyData] = useState({});
    const translate = useTranslate();
    const navigate = useNavigate();
    const [setCaptcha, setRecaptcha] = useState(true);
    const [selection, setSelection] = useState("registration")
    const [formValue, setFormValue] = useState("");
    const [formError, setFormError] = useState("")
    const [deviceDetail, setDeviceDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState("")
    const captcha = useRef();
    const handleRedirect = () => {
        navigate("/login");
    };

    const GetDeviceDetails = () => {
        setLoading(true)
        let query = ""
        if (selection === "registration") query = "?regNo=" + formValue
        else query = "?chasisNo=" + formValue
        fetch(urls?.PUBLIC_SEARCH_VEHICLE + query, {
            headers: {
                'content-type': 'application/json'
            }
        })
            .then((e) => e.json())
            .then((json) => {
                setDeviceDetail(json);
                setLoading(false);
                setSubmitError("")
                captcha.current.reset()
                setRecaptcha(true)
            })
            .catch((errors) => {
                setSubmitError("Device Not Found")
                setDeviceDetail({})
                setLoading(false);
                captcha.current.reset()
                setRecaptcha(true)
            })
    }

    const HandleSelection = (e) => {
        setSelection(e?.target?.value)
    }

    const HandleFormChange = (e) => {
        setFormValue(e?.target?.value)
    }

    const HandleSearch = () => {
        if (formValue === "") {
            if (selection === "registration") setFormError("Registration no is required")
            else setFormError("Chasis no is required")
        } else if (setCaptcha) {
            document.getElementById("rc-anchor-alert").innerHTML = "Please check the box above to proceed";
            setFormError("")
        } else {
            setFormError("")
            GetDeviceDetails();
        }
    }

    const HandleReset = () => {
        setFormValue("");
        setDeviceDetail({});
        setFormError("");
        setSubmitError("");
        captcha.current.reset();
        setRecaptcha(true)
    }

    return (
        <>
            <Form>
                <Container maxWidth="xxl" className="search-vehicle-sec">
                    <Grid container rowSpacing={2} className="bg-color">
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "2em"
                                }}
                                className="back-to-login"
                                onClick={handleRedirect}
                            >
                                <ArrowIcon className="arrow-icon" />
                                <Typography
                                    color="textSecondary"
                                    variant="h"
                                    component="h4"
                                >
                                    Back to Login
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={5} sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}>
                            <Card
                                sx={{
                                    minWidth: 300,
                                    padding: "18px 42px !important",
                                    border: "unset",
                                    boxShadow: "unset"
                                }}
                            >
                                <Box
                                    sx={{
                                        margin: "1em",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={10} className="logo-box">
                                            <img src={TransportLogo} />
                                            <Typography variant="h5" component="h2" align="center">
                                                Vehicle Tracking System
                                            </Typography>
                                            <Typography variant="p" component="p" align="center">
                                                Transport Department, Chhattisgarh
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ padding: "0 1em 1em 1em" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    <FormControlLabel value="registration" checked={selection === "registration"} control={<Radio />} label="Registration Number" onChange={HandleSelection} />
                                                    <FormControlLabel value="chassis" control={<Radio />} checked={selection === "chassis"} label="Chassis Number" onChange={HandleSelection} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ padding: "0 1em 1em 1em" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box>
                                                <TextField
                                                    placeholder="Enter"
                                                    name="search"
                                                    label={selection === "registration" ? translate("Registration Number") : "Chasis No"}
                                                    // inputProps={{ maxLength: 50, autocomplete: 'new-password', form: { autocomplete: "off" } }}
                                                    fullWidth
                                                    onChange={HandleFormChange}
                                                    value={formValue}
                                                />
                                                {formError && <span class="rc-anchor-alert">{formError}</span>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Reaptcha
                                            ref={captcha}
                                                sitekey={REACT_APP_SITE_KEY}
                                                onVerify={() => setRecaptcha(false)}
                                                size="normal"
                                                className="reaptcha"
                                            />
                                            {setCaptcha &&
                                                <div id="rc-anchor-alert" class="rc-anchor-alert"></div>
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                                <CardActions sx={{ padding: "0 1em 1em 1em", marginTop: "1em" }}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        fullWidth
                                        onClick={HandleSearch}
                                    >
                                        Search Vehicle
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                        fullWidth
                                        className="reset-btn"
                                        onClick={HandleReset}
                                    >Reset
                                    </Button>
                                </CardActions>
                            </Card>
                            {!deviceDetail?.name && (<Box component="section" sx={{ textAlign: 'center' }}>
                                <h6>Find Vehicle Details</h6>
                                <p>To find the information you need,please enter either the <br></br>Registration Number or the Chassis Number.</p>
                            </Box>)}
                        </Grid>
                        {loading && <Spinner />}
                        {submitError && <Grid item xs={12} sx={{ padding: "2em 1em" }}><div className="d-flex"><span style={errorstyle}>{submitError}</span></div></Grid>}
                        {deviceDetail?.name && (<><Grid item xs={12} sx={{ padding: "2em 1em" }}>
                            <div className="d-flex">
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    color="primary"
                                    fullWidth
                                    className="vehicle-num-btn"
                                ><FiberManualRecordIcon sx={{ marginRight: "1em" }} className="dot-icon" />{deviceDetail?.name}<FiberManualRecordIcon sx={{ marginLeft: "1em" }} className="dot-icon" />
                                </Button>
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    color="primary"
                                    fullWidth
                                    className="active-btn"
                                >{deviceDetail?.status}
                                </Button>
                            </div>
                        </Grid>
                            <Grid item xs={12} sx={{ padding: "0 1em" }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <h6>{deviceDetail?.rto_code}</h6>
                                                    <label>RTO Code</label>
                                                </TableCell>
                                                <TableCell>
                                                    <h6>{deviceDetail?.phone}</h6>
                                                    <label>Permit Holder Number</label>
                                                </TableCell>
                                                <TableCell>
                                                    <h6>{deviceDetail?.uniqueId}</h6>
                                                    <label>IMEI</label>
                                                </TableCell>
                                                <TableCell>
                                                    <h6>{moment(deviceDetail?.deviceActivatedUpto).format("DD/MM/YYYY")}</h6>
                                                    <label>Activated Upto</label>
                                                </TableCell>
                                                <TableCell>
                                                    <h6>{deviceDetail?.chasisno}</h6>
                                                    <label>Chassis Number</label>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <h6>{deviceDetail?.category}</h6>
                                                    <label>Vehicle Type</label>
                                                </TableCell>
                                                <TableCell>
                                                    <h6>{deviceDetail?.vltdmodel}</h6>
                                                    <label>Maker Model</label>
                                                </TableCell>
                                                <TableCell>
                                                    <h6>{deviceDetail?.vstate}</h6>
                                                    <label>State</label>
                                                </TableCell>
                                                <TableCell>
                                                    <h6>{deviceDetail?.permit_holder}</h6>
                                                    <label>Permit Holder</label>
                                                </TableCell>
                                                <TableCell>
                                                    <h6>{deviceDetail?.vltd_make}</h6>
                                                    <label>VLTD Make</label>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid></>)}
                    </Grid>
                </Container>
            </Form>
        </>
    );
};

export default SearchVehicle; 