import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { useListContext, useResourceContext, FilterFormBase, ListContext, useListFilterContext } from 'react-admin';
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const VTSFilterForm = (props) => {
    //let filtertext = "";
    const [filtertext, setfiltertext] = useState("")
    const  resource  = useResourceContext(props);
    const { filters, date, select, setGroupId, setRtoCode, setVltdModel, setVltdMake, type, setSelected, setDateRange, ...rest } = props;
    const {
        displayedFilters,
        filterValues,
        setFilters
    } = useListFilterContext();

       
    useEffect(()=>{
        if(resource!=="vehicles") 
        setfiltertext("")
        else
        setfiltertext(filterValues)
    },[resource])
        

    const form = useForm({
        defaultValues: filtertext,
    });


    useEffect(()=>{
       if(typeof(filterValues.q) ==="undefined")form.reset();
    },[filterValues])

    useEffect(()=>{
       if(resource!=="vehicles") setFilters("")
    },[])
    
    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            form.handleSubmit(onSubmit)();
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, [filterValues, date?.current, select?.current]);

    const onSubmit = (values) => {

        let start = undefined, end = undefined;
        if(date !== undefined) { 
          start = date.current?.props?.startDate;
          end = date.current?.props?.endDate;
          if (start !== undefined && end !== undefined) {
            form.setValue("startDate", start);
            form.setValue("endDate", end);
        }
      }
      if(select?.current){
       
        form.setValue("selected", document
              .getElementById("mui-component-select-status")
              .innerText.toLowerCase());
      }
        if (Object.keys(values).length > 0) {
          setFilters(form.getValues());
        }
    };


    const handleRefresh = () =>{
        form.reset()
        if(resource == 'groups' && Object.entries(filterValues).length == 0){
            setVltdMake(null)
            setVltdModel(null)
        }
        else if(resource == 'PanicDetails')
        {
            setSelected({
                status: "all",
                from_page: null
              })
            let todate = new Date();
            let prvDate = new Date(todate.getFullYear(), todate.getMonth() - 1, todate.getDate());
            setDateRange([prvDate, todate])
            setFilters({})
        }
        else if(Object.entries(filterValues).length == 0){
            setGroupId(null) 
            setRtoCode(null)            
        }
        else if('group_id' in filterValues && ('q' in filterValues)){            
            setFilters({})
            setGroupId(null)  
        }
        else if(resource == 'groups' && ('q' in filterValues)){          
            setFilters({})
            setVltdMake(null)
            setVltdModel(null)  
        }
       
        else setFilters({})
    }

   

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} filters={filters} {...rest} className="vts-form">
            <Box display="flex" alignItems="flex-end" mb={1}>
                <Box component="span" mr={2}>
                    {
                        <FormProvider {...form}>
                            <FilterFormBase
                                onSubmit={onSubmit}
                                filters={filters}
                                {...rest}
                            />
                        </FormProvider>
                        
                    }
                </Box>
                <Box  mr={2} mb={1.5}>
                    {/* <Button variant="outlined" color="primary" type="submit">
                        Submit
                    </Button>   */}
                    <IconButton size="small" className='search-btn' type="submit">
                        <SearchIcon fontSize="inherit" />
                    </IconButton>                 
                </Box>
                {(resource == 'vehicles' || resource == 'route' || resource == 'PanicDetails') && <Box style={{position: 'absolute',right:"4px",bottom:"4px"}}>
                <Button variant="outlined" startIcon={<RefreshIcon />} className="refreshbtn" onClick={handleRefresh}>
                        Refresh
                    </Button>
                </Box>}

                {type == 'overview' && <Box style={{position: 'absolute',right:"4px"}}>
                <Button variant="outlined" startIcon={<RefreshIcon />} className="refreshbtn" onClick={handleRefresh}>
                        Refresh
                    </Button>
                </Box>}

                {type == 'entityRefresh'  && <Box style={{position: 'absolute',right: "375px",bottom:"4px"}}>
                <Button variant="outlined" startIcon={<RefreshIcon />} className="refreshbtn" onClick={handleRefresh}>
                        Refresh
                    </Button>
                </Box>}
            </Box>
        </form>
    );
};

export default VTSFilterForm;