import React, { useState, useEffect } from 'react';
import {
  ReferenceField,
  TextField,
  useListController,
  Datagrid,
  useRecordContext,
  ListContextProvider,
  TextInput,
  Pagination,
  FunctionField,
  TopToolbar,
  Confirm,
  useRefresh,
  ResourceContextProvider
} from "react-admin";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import {
  Grid,
  Card
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import "../views/route/route.css";
import AddButton from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MapView from './../components/mapview';
import SelectedList from './../components/Accordian';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRowsIcon from '@mui/icons-material/TableRowsOutlined';
import DistanceIcon from '@mui/icons-material/RouteOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import PointTypeIcon from '@mui/icons-material/PinDropOutlined';
import { RouteAssign } from './../components/RouteAssign';
import { LinearProgress } from '@mui/material';
import VTSListToolbar from '../components/VTSListToolbar';
import moment from "moment";
import { PanelRAssign } from '../utils/PanelRoutelist';
import SendNotification from "../utils/sendNotification";
import vtsDataProvider from './../providers/dataProvider'
import useMediaQuery from '@mui/material/useMediaQuery';


const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  flexRow: { display: 'flex', flexDirection: 'row' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};
const Buttonstyle = {
  position: 'absolute',
  background: '#4646F2',
  color: '#fff',
  zIndex: 1,
  textTransform: 'capitalize',
  width: '130px',
  border: '1px solid #4546F2'
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const defaultValues = {
  name: "",
  phone: "",
  email: "",
  password: "",

};

const position = [51.505, -0.09]


const TabPanel = props => {

  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && (

        <Typography>{children}</Typography>

      )}
    </React.Fragment>
  )
}




export const RouteAssignList = ({ createper, updateper, deleteper }) => {
  const [notifyData, setNotifyData] = useState({});
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [assignexpanded, setAssignExpand] = React.useState(false);
  const [filter, setFilter] = useState('');
  const [RAssignId, setRAssignId] = useState();
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  });
  const refresh = useRefresh();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const matches = useMediaQuery('(min-width:1800px)');


 const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };
  
  const perPage = 10;
  const { data, isLoading, page, setPage, setPerPage, ...listContext } = useListController({
    resource: 'routeassign'
  });

  const filters = [<TextInput label="Search" source="q" size="small" alwaysOn />];
  const filterValues = { q: filter };
  const setFilters = filters => setFilter(filters.q);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const AssignActions = () => (
    <TopToolbar className='route-toolbar'>
      {createper && (<><AddButton className="create-btn" startIcon={<AddIcon />} sx={Buttonstyle} expand={assignexpanded} aria-expanded={assignexpanded} aria-label="show" onClick={() => { setAssignExpand(true); }}>Assign Route</AddButton></>)}
    </TopToolbar>
  );
  const RoutnameField = ({ source }) => {
    const record = useRecordContext();
    return (
      <span style={{ color: "#4646F2", display: "flex" }}><RouteIcon /><span>{record[source]}</span>
      </span>
    )
  };

  const TripStatus = ({ source }) => {
    let status = "Trip Created Not Started"
    const record = useRecordContext();
    if (record.trip.status === "started")
      status = "Trip is active and started"
    else if (record.trip.status === "finished")
      status = "Trip Finished"
    return (
      <>
        <span>{status}</span>
      </>
    );
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = (record[source] !== null) ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss") : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const handleDeleteClick = (event, deleteId) => {
    setDeleteDialog({
      open: true,
      deleteId: deleteId
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete('routeassign', { id: deleteDialog.deleteId }, setNotifyData, "Route Assign Deleted Succesfully", "Failed to Delete");
      handleConfirmClose();
    }
  };

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
  };


  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <DeleteIcon
          onClick={(event) => handleDeleteClick(event, record.trip.id)}
        />
      </div>
    );
  };

  const handleEditClick = (event, RAssignId) => {
    setRAssignId(RAssignId);
    setAssignExpand(true);
  }

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon
          onClick={(event) => handleEditClick(event, record.trip.id)}
        />
      </div>
    );
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  if (isLoading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  } else
    return (
      <>
        {assignexpanded === false ?
          <ResourceContextProvider value="routeassign">
            <ListContextProvider debounce={8000} value={{ data, filterValues, page, setFilters, setPage, setPerPage, ...listContext }}>
              <VTSListToolbar bulkActionButtons={false} filters={filters} actions={<AssignActions />} />
              {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
             <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
  	          )}
              <Box className="list-content">
                <Datagrid bulkActionButtons={false} expand={<PanelRAssign />}  empty={<CustomEmpty />}>
                  <TextField source="route.numStoppage" label="Sequence" />
                  {/* <TextField source="trip.routeId" label="Route Id" />
              <TextField source="trip.id" label="ID" /> */}
                  <FunctionField label="Route Name" render={
                    record => <span style={{ color: "#4646F2", display: "flex" }}><RouteIcon /><span>{`${record?.route?.name}`}</span>
                    </span>
                  } />
                  <TextField source="route.routeType" label="Route Type" />
                  <TextField source="device.name" label="Entity" />
                  <TripStatus source="trip.status" label="Trip Status" />
                  <TextField source="trip.initialOdometer" label="Initial Odometer" />
                  <TextField source="trip.closingOdometer" label="Closing Odometer" />
                  <FunctionField label="Start Time" render={
                    record => (record.trip.startTime !== null) ? moment(record.trip.startTime).format("DD MMM YYYY, HH:mm:ss") : ""
                  } />
                  <FunctionField label="End Time" render={
                    record => (record.trip.endTime !== null) ? moment(record.trip.endTime).format("DD MMM YYYY, HH:mm:ss") : ""
                  } />
                  <TextField source="trip.remarks" label="Tag" />
                  <FunctionField label="Assign Time" render={
                    record => (record.trip.assignedTime !== null) ? moment(record.trip.assignedTime).format("DD MMM YYYY, HH:mm:ss") : ""
                  } />
                  <ReferenceField label="Assigned By" source="trip.assignedBy" reference="users">
                    <TextField source="name" />
                  </ReferenceField>
                  <TextField source="trip.status" label="Status" />

                  <span label="Action" className="action-icons">
                    {updateper && (<><CustomEditButton source="id" /></>)}
                    {deleteper && (<><CustomDeleteButton source="id" /></>)}
                  </span>
                </Datagrid>
              </Box>
              
            </ListContextProvider>
          </ResourceContextProvider>
          :
          <RouteAssign setAssignExpand={setAssignExpand} RAssignId={RAssignId}></RouteAssign>
        }

        <Confirm
          isOpen={deleteDialog?.open}
          title="Delete Route Assign"
          content="Are you sure you want to delete the Route Assign?"
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmClose}
        />
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} />

      </>
    );
}
