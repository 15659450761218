import { useContext, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import urls from '../constants';
import { setCookie } from '../utils/cookies';
import { PositionContext } from '../utils/PositionContext';
import { exists } from '../utils/validate';
import useThrottle from '../hooks/useThrottle';

function usePosition(){
  //Connect to remote websocket and wait for messages
  let wsUrl = urls.WEBSOCKET_URL.replace(/http/, 'ws');

  const [socketUrl, setSocketUrl] = useState(wsUrl + "?deviceId=0");
  const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl);
  const {positions, setPositions} = useContext(PositionContext);
  //const throttledPositions = useThrottle(positions, 100);

  
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  //If new message arrives the side effect gets called
  useEffect(() => {
    if (exists(lastMessage)) {
      let obj = JSON.parse(lastMessage.data);
      if(exists(obj) && Array.isArray(obj.positions)) {
        //console.log("Position of ID " + obj.positions[0].deviceId + ': ' + obj.positions[0].latitude + ':' + obj.positions[0].longitude);
        if(JSON.stringify(obj.positions) !== JSON.stringify(positions)) {
          setPositions(obj.positions);
        }
      }
    }else{
      console.log("Websocket " + socketUrl + " in state:" + connectionStatus);
    }
    //  return(()=>{
    //    getWebSocket()?.close();
    //  });
  }, [lastMessage, socketUrl]);

  return [ positions, setPositions, setSocketUrl ];
}

export default usePosition;