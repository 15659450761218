import React, { useState, useEffect } from 'react';
import { Button, useListController, useRedirect, useGetOne, useGetList, useRefresh } from "react-admin";
import { Box, Typography } from '@mui/material';
import {
  Grid,
  Card
} from '@mui/material';
import "../views/route/route.css";
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormField from '@mui/material/TextField';
import SelectedList from './../components/Accordian';
import vtsDataProvider, { getRouteAddress, getRoutelinePoints, getVehicleLocation } from './../providers/dataProvider';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import moment from 'moment';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CreateRouteMap from './../components/CreateRouteMap'
import SendNotification from "../utils/sendNotification";
import { validateLatLong } from "../utils/validate"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  flexRow: { display: 'flex', flexDirection: 'row' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, geofence, theme) {
  return {
    fontWeight:
      (geofence.name).indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



export const CreateRoute = ({ setExpanded, RouteId, setRouteId, listContext }) => {
  const theme = useTheme();
  const defaultValues = {
    name: "",
    routetype: "",
    stoptypes: "",
    description: ""
  }
  const [notifyData, setNotifyData] = useState({});
  const [formValues, setFormValues] = useState(defaultValues);
  const [geofences, SetGeofences] = useState([])
  const [stopType, SetStopType] = useState({})
  const [routeType, SetRouteType] = useState({})
  const [legDetails, setlegDetails] = useState([])
  const [legSubDetails, setlegSubDetails] = useState([])
  const [address, setResponse] = useState();
  const [RoutePoints, setRoutePoints] = useState([]);
  const [showlegPoints, setShowLegPoints] = useState([]);
  const [RouteSubmitDetails, setRouteSubmitDetails] = useState({});
  const [EditRouteId, setEditRouteId] = useState(0)
  const [EditRouteDetails, setEditRouteDetails] = useState({})
  const [SaveEditDetails, setSaveEditDetails] = useState({})
  const [formErrors, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isdeleteRoute, setdeleteRoute] = useState(false);
  const [legPoints, setlegPoints] = useState({
    name: formValues.name,
    points: []
  });

  const [dropgeofences, setdropgeofences] = useState([]);
  const [geofencechange, setgeofencechange] = useState([]);
  const [geofencelist, setGeofence] = React.useState([]);
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [PointsList, setPointsList] = useState([{ latitude: "", longitude: "", radius: "200", errors: "", randomid: Math.floor(Math.random() * 1001) }]);

  const filterstopType = { filter: { type: "SelectStopType" } }
  const filterrouteType = { filter: { type: "SelectRouteType" } }

  const { data: geofencesdata } = useGetList('geofencesdrop');
  const { data: stopTypedata } = useGetList('stoptype', filterstopType);
  const { data: routeTypedata } = useGetList('stoptype', filterrouteType);

  const { data: EditRoute, isLoading, error } = useGetOne(
    'route',
    { id: EditRouteId },
    { enabled: (EditRouteId !== 0) }
  );

  useEffect(() => {
    setFormError({});
    setFormValues(defaultValues);
    SetGeofences([]);
    setlegDetails([]);
    setShowLegPoints([]);
    setEditRouteId(RouteId);
  }, [])

  useEffect(() => {
    const getRouteDrop = () => {
      if (stopTypedata)
        SetStopType(stopTypedata[0])
      if (routeTypedata)
        SetRouteType(routeTypedata[0])
      if (geofencesdata)
        SetGeofences(geofencesdata)
    }
    getRouteDrop();

  }, [geofencesdata, stopTypedata, routeTypedata])

  useEffect(() => {
    if (geofences) {
      var value = geofences.filter(function (item) {
        let startpoint = item.area.indexOf("(");
        let geofencetype = item.area.substring(0, startpoint).trim();
        geofencetype = geofencetype.toLowerCase();

        return geofencetype === "circle"
      })
      const list = formatDevices(value);
      setdropgeofences(list);
    }
  }, [geofences]);


  const formatDevices = (list) => {
    var te = list?.map((data) => ({
      label: (data?.id).toString() + " - " + data?.name.toString(),
      value: data?.id,
      showlabel: data?.name,
    }));
    return te;
  };

  useEffect(() => {
    const getRouteDetails = () => {
      if (EditRoute) {
        let routearr = [];
        setEditRouteDetails(EditRoute)
        EditRoute.legs.map((Routes, index) => {
          let temp = {};

          if (index === 0) {
            if (checknull(Routes.nameFrom) !== "")
              temp.name = Routes.nameFrom;
            else
              temp.name = "User_Entry_" + index;

            var value = geofences.filter(function (item) {
              return item.name === Routes.nameFrom
            })
            if (value.length > 0)
              temp.from = "geofence"
            else
              temp.from = "userentry"

            temp.userindex = index
            temp.latitude = Routes.latitudeFrom;
            temp.longitude = Routes.longitudeFrom
            temp.radius = Routes.radiusFrom
            routearr.push(temp)
          }

          temp = {
            name: "User_Entry_" + (index * 1) + 1,
            latitude: 0,
            longitude: 0,
            radius: 0,
            from: "userentry",
            userindex: (index * 1) + 1
          };

          if (checknull(Routes.nameTo) !== "")
            temp.name = Routes.nameTo;

          var value = geofences.filter(function (item) {
            return item.name === Routes.nameTo
          })
          if (value.length > 0) temp.from = "geofence"

          temp.latitude = Routes.latitudeTo;
          temp.longitude = Routes.longitudeTo
          temp.radius = Routes.radiusTo
          routearr.push(temp)
        })

        setTimeout(() => {
          const formValuescopy = formValues;

          formValuescopy.name = checknull(EditRoute?.name);
          formValuescopy.description = checknull(EditRoute?.description);

          if (routeType?.configurations) {
            const route_type = Object.values(routeType?.configurations).find((type) => type === checknull(EditRoute?.routeType));
            if (route_type) {
              formValuescopy.routetype = checknull(EditRoute?.routeType);
            }
          } else { formValuescopy.routetype = "" }
          if (stopType?.configurations) {
            const stop_type = Object.values(stopType?.configurations).find((type) => type === checknull(EditRoute?.stopType));
            if (stop_type) {
              formValuescopy.stoptypes = checknull(EditRoute?.stopType);
            }
          } else { formValuescopy.stoptypes = "" }

          setFormValues(formValuescopy)

          setShowLegPoints(routearr)
        }, 1000);
      }

    }
    getRouteDetails();
  }, [EditRoute, geofences, stopType, routeType])

  const checknull = (value) => {
    if (value === null || typeof (value) === "undefined" || value === NaN || value === "")
      return ""
    else
      return value;
  }

  const handlePointsChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...PointsList];
    list[index][name] = value;
    setPointsList(list);
  };

  const handlePointsRemove = (index) => {
    const list = [...PointsList];
    const temp_index = list.findIndex(x => x.randomid === index);

    // var value = list.filter(function (item) {
    //   return item.randomid !== index
    // })
    list.splice(temp_index, 1);
    setPointsList(list);
    if (PointsList && PointsList.length === 1) handlePointsAdd()
  };

  useEffect(() => {
    let geo_arr = [];
    geofencechange.map((geo) => {
      geo_arr.push(geo.value)
    })
    setGeofence(geo_arr);
  }, [geofencechange])

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   setGeofence(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };


  const geofenceRemove = (geoId) => {
    if (legDetails && legDetails.length === 1)
      setlegDetails([])
    var value = geofencechange.filter(function (item) {
      return item.value !== geoId
    })
    setgeofencechange(value)
    // var value = geofencelist.filter(function (item) {
    //   return item !== geoId
    // })
    // setGeofence(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let regex = /^[A-Za-z0-9-_ ]+$/
    if (name == "name") {
      if (value == "" || regex.test(value)) {
        setFormValues
        ({
          ...formValues,
          [name]: checknull(value),
        })
      }
    }
    else{
      setFormValues
      ({
        ...formValues,
        [name]: checknull(value),
      })
    }    
  }

  const handlePointsAdd = () => {
    const list = [...PointsList];
    let validate = true;
    PointsList.map(async (points, index) => {
      if (points.latitude.trim() === "") {
        list[index].errors = "Please fill the latitude"
        validate = false;
      }
      else if (points.longitude.trim() === "") {
        list[index].errors = "Please fill the longitude"
        validate = false;
      }
      else if (points.radius.trim() === "") {
        list[index].errors = "Please fill the radius"
        validate = false;
      }
      else if (points.latitude.trim() !== "" && points.longitude.trim() !== "") {
        if (!validateLatLong(points.latitude, points.longitude)) {
          list[index].errors = "This is not a valid latitude and longitude!";
          validate = false;
        }
        else {
          const address = {};
          const record = { latitude: points.latitude, longitude: points.longitude }
          await getVehicleLocation(record, address)
            .then((res) => {
              if (address?.address === "" || address?.address === undefined) {
                list[index].errors = "This is not a valid latitude and longitude!";
                validate = false;
              }
              else {
                list[index].errors = "";
              }
            }
            ).catch((error) => {
              validate = false;
              list[index].errors = "This is not a valid latitude and longitude!";
            })

        }
      }
      else {
        list[index].errors = "";
      }
    })
    setPointsList(list);
    const timer = setTimeout(() => {
      if (validate === true)
        setPointsList([...PointsList, { latitude: "", longitude: "", radius: "200", errors: "", randomid: Math.floor(Math.random() * 1001) }]);
    }, 2000);
  };




  const validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = "Route name is required!";
    if (!values.stoptypes) errors.stoptypes = "Select stop type";
    if (!values.routetype) errors.routetype = "Select route type!";

    if (legDetails.length < 2 && EditRouteId === 0) errors.routecount = "Select or Enter two location!";
    return errors;
  };

  useEffect(() => {
    let temparr = [];
    let existsarr = [];
    let notexistsarr = [];

    let showlegPointscopy = showlegPoints;
    // if(isdeleteRoute === false)
    // {
    if (PointsList) {
      if (PointsList.length > 0) {
        PointsList.map((points, index) => {
          if (points.latitude !== "" && points.longitude !== "" && points.radius !== "") {
            if (!validateLatLong(points.latitude, points.longitude))
              PointsList[index].errors = "This is not a valid latitude and longitude!";
            else {

              PointsList[index].errors = "";

              let temp = {
                name: "User_Entry_" + index,
                latitude: points.latitude,
                longitude: points.longitude,
                radius: points.radius,
                from: "userentry",
                plandate: moment(new Date()).format("DD MMM YYYY hh:mm:ss"),
                // userindex: index,
                userindex: points.randomid,
              };

              existsarr.push(temp)


              let chk_points = showlegPoints.find((leg_points) => leg_points.userindex === temp.userindex && leg_points.from === "userentry");
              if (chk_points) {
                // existsarr.push(temp)
                showlegPointscopy = showlegPointscopy.map(obj => {
                  // 👇️ if id equals 2, update country property
                  if (obj.userindex === temp.userindex && obj.from === "userentry") {
                    return { ...obj, latitude: points.latitude, longitude: points.longitude };
                  }

                  // 👇️ otherwise return object as is
                  return obj;
                });

              }
              else
                notexistsarr.push(temp)
            }
            //temparr.push(temp)
          }
        })
      }
    }

    if (geofencelist) {
      if (geofencelist.length > 0) {
        geofencelist.map(async (geofen, index) => {
          let temp = {
            name: "",
            latitude: 0,
            longitude: 0,
            radius: 0,
            from: "geofence",
            plandate: moment(new Date()).format("DD MMM YYYY hh:mm:ss"),
            userindex: geofen
          };

          var value = geofences.filter(function (item) {
            return item.id == geofen
          })
          temp.userindex = value[0].id;
          let area = value[0].area;
          let startpoint = area.indexOf("(");
          let endpoint = area.indexOf(")");
          let latlngs = area.substring(startpoint + 1, endpoint).trim();
          const radarray = latlngs.split(",")
          let radius = radarray[1].trim();
          const positionarray = radarray[0].split(" ")
          let lat = positionarray[0].trim();
          let lng = positionarray[1].trim();
          temp.name = value[0].name
          temp.latitude = lat * 1
          temp.longitude = lng * 1
          temp.radius = radius * 1

          if (!validateLatLong(lat * 1, lng * 1)) {
            formErrors.routecount = value[0].name + " geofence is not contain valid latitude and longitude!";
            const timer = setTimeout(() => {
              geofenceRemove(geofen);

            }, 3000);

          }
          else {
            formErrors.routecount = "";

            existsarr.push(temp)

            let chk_points = showlegPoints.find((leg_points) => leg_points.userindex === temp.userindex && leg_points.from === "geofence");

            if (chk_points) {
              // existsarr.push(temp)
              showlegPointscopy = showlegPointscopy.map(obj => {
                // 👇️ if id equals 2, update country property
                if (obj.userindex === temp.userindex && obj.from === "geofence") {
                  return { ...obj, latitude: temp.latitude, longitude: temp.longitude };
                }

                // 👇️ otherwise return object as is
                return obj;
              });

            }
            else
              notexistsarr.push(temp)


            // if (!chk_points)
            //   notexistsarr.push(temp)
          }
          // temparr.push(temp)
        })
      }
    }


    temparr = showlegPointscopy.concat(notexistsarr)
    const myArray = temparr.filter(ar => existsarr.find(rm => (rm.userindex === ar.userindex && ar.from === rm.from)))


    //temparr = existsarr.concat(notexistsarr);
    setShowLegPoints(myArray)
    // }
    // else
    // {
    //   setdeleteRoute(false);
    // }
  }, [PointsList, geofencelist])


  useEffect(() => {

    if (showlegPoints) {
      if (showlegPoints.length > 0) {
        let legs = [];
        let legsSub = [];
        showlegPoints.map(async (legpoint, index) => {
          let legName = legpoint.name;

          var value = legDetails.filter(function (item) {
            return item.userindex == legpoint.userindex
          })

          if (value.length > 0) legName = value[0].name

          let details = {
            name: legName,
            latitude: legpoint.latitude * 1,
            longitude: legpoint.longitude * 1,
            radius: legpoint.radius * 1,
            address: "",
            distance: 0,
            plandate: legpoint.plandate,
            from: legpoint.from,
            userindex: legpoint.userindex,
            subRoutePoints: {}
          };
          let detailssub = {
            name: legName,
            latitude: legpoint.latitude * 1,
            longitude: legpoint.longitude * 1,
            radius: legpoint.radius * 1
          };
          const filter = { filter: { details } }
          legsSub.push(detailssub)
          legs.push(details)
          await getRouteAddress("routeaddress", filter, legs, index)

          if (index > 0) {
            let subRoutePoints =
            {
              name: formValues.name,
              points: legsSub
            }
            await getRoutelinePoints("legdetails", subRoutePoints, legs, index)
          }

          const temp_index = PointsList.findIndex(x => x.randomid === legs[index].userindex);


          const timer = setTimeout(() => {
            // if (legs[index].address === "" && legs[index].from === "userentry" && EditRouteId === 0) {
            //   PointsList[temp_index].errors = "This is not a valid latitude and longitude!";
            //   legs.splice(index, 1)
            //   legsSub.splice(index, 1)
            // }
            // else if (legs[index].address === "" && legs[index].from === "geofence" && EditRouteId === 0) {
            //   setFormError({ routecount: legs[index].name + "  geofence not contain valid latitude and longitude" });
            //   //geofenceRemove(legs[index].userindex)
            //   legs.splice(index, 1)
            //   legsSub.splice(index, 1)
            // }
            // else {
            //   if (legs[index].from === "geofence" && EditRouteId === 0) setFormError({ routecount: "" });
            //   if (legs[index].from === "userentry" && EditRouteId === 0) PointsList[temp_index].errors = "";
              setlegSubDetails(legsSub)
              setlegDetails(legs)
           // }

          }, 2000);
        })

      } else {
        setlegSubDetails([])
        setlegDetails([])
      }
    }
    else {
      setlegSubDetails([])
      setlegDetails([])
    }
  }, [showlegPoints])


  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {

      if (EditRouteId > 0) {
        const copyEditRouteDetails = EditRouteDetails;
        copyEditRouteDetails.name = formValues.name;
        copyEditRouteDetails.stopType = formValues.stoptypes;
        copyEditRouteDetails.routeType = formValues.routetype;
        copyEditRouteDetails.description = formValues.description;
        copyEditRouteDetails.legs.map((edit_route, index) => {
          edit_route.nameFrom = legSubDetails[index].name;
          edit_route.nameTo = legSubDetails[(index * 1) + 1].name;
        })
        setSaveEditDetails(copyEditRouteDetails)
      } else {
        setRouteSubmitDetails(
          {
            name: formValues.name,
            points: legSubDetails,
            stopType: formValues.stoptypes,
            routeType: formValues.routetype,
            description: formValues.description
          }
        )
      }

    }
  }, [formErrors])


  const handleRouteSubmit = (event) => {
    event.preventDefault();
    setFormError(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (RouteSubmitDetails.name !== "" && typeof (RouteSubmitDetails.name) !== "undefined") {
      vtsDataProvider.create('route', RouteSubmitDetails, setNotifyData, "Route Created Successfully", "Failed to Create Route");
      setFormValues(defaultValues)
      setRouteSubmitDetails({})
      setFormError({});
      setIsSubmit(false);
      const timer = setTimeout(() => {
        setExpanded(false);
      }, 5000);

    }
  }, [RouteSubmitDetails])

  useEffect(() => {
    if (SaveEditDetails.name !== "" && typeof (SaveEditDetails.name) !== "undefined") {
      vtsDataProvider.update('route', SaveEditDetails, setNotifyData, "Route Updated Successfully", "Failed to Update Route");
      setFormValues(defaultValues)
      setSaveEditDetails({})
      setFormError({});
      setIsSubmit(false);
      setRouteId(0);
      setEditRouteId(0);
      const timer = setTimeout(() => {
        setExpanded(false);
      }, 5000);
    }
  }, [SaveEditDetails])

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const backtoRoute = () => {
    setEditRouteId(0);
    setFormValues(defaultValues)
    setFormError({});
    setRouteId(0);
    setIsSubmit(false);
    setExpanded(false);
  }

  return (
    <>
      <div style={{ display: "flex" }} className="backtopage" onClick={() => { backtoRoute(); }}>
        <ArrowIcon className='arrow-icon' />
        <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'>Back to Route List
        </Typography>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }} className="route-list-sec">
        <Card
          sx={{
            minHeight: 52,
            minWidth: 250,
            display: 'flex',
            width: '25%',
            height: '73vh',
            overflowY: 'auto',
            paddingRight: '0px',
            flexDirection: 'column',
            '& a': {
              textDecoration: 'none',
              color: 'inherit',
            },
          }}
          className="first-card"
        >

          <form onSubmit={handleRouteSubmit}>
            <Box textAlign="left" className='audit-left'>
              <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                sx={{
                  marginBottom: '15px'
                }}>{EditRouteId > 0 ? "Edit Route" : "Create new Route"}
              </Typography>
              <Box sx={{ marginTop: '1em' }} style={styles.flexColumn}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Route Name</FormLabel>
                  <FormField
                    name="name"
                    placeholder="Enter Name"
                    type="text"
                    value={formValues.name}
                    onChange={handleInputChange}
                  />
                  <div style={errorstyle}>
                    {formErrors.name}
                  </div>
                </FormControl>
              </Box>
              <Box sx={{ flexGrow: 1, marginTop: '1em' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel>Stop Type</FormLabel>
                    <Select sx={{ background: "#f0f0ff" }}
                      placeholder="Select"
                      name="stoptypes"
                      value={formValues?.stoptypes}
                      onChange={handleInputChange}>

                      {stopType ?
                        stopType?.configurations ?
                          Object.values(stopType?.configurations).map((stop, index) => (
                            <MenuItem key={index} value={stop}>
                              {stop}
                            </MenuItem>
                          )) : "" : ""
                      }
                    </Select>
                    <div style={errorstyle}>
                      {formErrors.stoptypes}
                    </div>
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel>Route Type</FormLabel>
                    <Select sx={{ background: "#f0f0ff" }}
                      placeholder="Select"
                      name="routetype"
                      value={formValues?.routetype}
                      onChange={handleInputChange}
                    >
                      {
                        routeType ?
                          routeType?.configurations ?
                            Object.values(routeType?.configurations).map((route, index) => (
                              <MenuItem key={index} value={route}>
                                {route}
                              </MenuItem>
                            )) : "" : ""
                      }
                    </Select>
                    <div style={errorstyle}>
                      {formErrors.routetype}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ marginTop: '1em' }}>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <TextareaAutosize
                    name="description"
                    aria-label="empty textarea"
                    placeholder='Write here...'
                    style={{
                      background: "#f0f0ff",
                      height: "40px",
                    }}
                    value={formValues.description}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Box>
              <hr />
              {
                RouteId > 0 ? "" :
                  <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                    sx={{
                      marginBottom: '15px'
                    }}>Enter Location
                  </Typography>
              }
              {
                RouteId > 0 ? "" :
                  PointsList.map((data, index) => {
                    const { latitude, longitude, radius, errors, randomid } = data;
                    return (
                      <Box sx={{ flexGrow: 1, marginTop: '1em' }} key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={5} sx={{ display: "flex", flexDirection: "column" }}>
                            <FormLabel>Latitude</FormLabel>
                            <FormField
                              name="latitude"
                              placeholder="Enter"
                              type="text"
                              value={latitude}
                              onChange={(e) => handlePointsChange(e, index)}
                            />
                          </Grid>
                          <Grid item xs={5} sx={{ display: "flex", flexDirection: "column" }}>
                            <FormLabel>Longitude</FormLabel>
                            <FormField
                              name="longitude"
                              placeholder="Enter"
                              type="text"
                              value={longitude}
                              onChange={(e) => handlePointsChange(e, index)}
                            />
                          </Grid>
                          {/* <Grid item xs={10}>
                                    <FormControl>
                                        <FormLabel>Radius</FormLabel>
                                        <FormField 
                                        name="radius" 
                                        placeholder="Enter" 
                                        type="text" 
                                        value={radius}
                                         onChange={(e) => handlePointsChange(e, index)}
                                        />
                                    </FormControl>
                                  </Grid> */}
                          <Grid item xs={2}>
                            {PointsList.length !== 1 && (
                              <button className="btn remove" onClick={() => handlePointsRemove(randomid)}>x</button>
                            )}
                          </Grid>
                          <div style={errorstyle}>{errors}</div>

                        </Grid>

                      </Box>
                    )
                  })
              }

              {RouteId > 0 ? "" :
                <Box sx={{ flexGrow: 1, marginTop: '1em' }}>
                  <Button className='show-btn add' onClick={handlePointsAdd}>
                    Add
                  </Button>
                </Box>
              }

              {RouteId > 0 ? "" :
                <Box sx={{ flexGrow: 1, marginTop: '1em' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className='or-hr' sx={{ display: "flex" }}>
                        <hr /><span> Or </span><hr />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                  </Grid>
                </Box>}
              {RouteId > 0 ? "" :
                <Box sx={{ marginTop: '1em' }}>
                  <div style={errorstyle}>
                    {formErrors.routecount}
                  </div>
                  <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                    sx={{
                      marginBottom: '15px'
                    }}>Select existing Stops
                  </Typography>

                  <FormControl>
                    {/* <FormLabel>Stops</FormLabel> */}
                    <Stack spacing={3}>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        renderOption={(props, option) => (
                          <li {...props} key={option.value}>
                            {option.showlabel}
                          </li>
                        )}
                        options={dropgeofences}
                        getOptionLabel={(option) => option.showlabel}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Stops"
                            placeholder="Select"
                          />
                        )}
                        value={geofencechange}
                        onChange={(event, newValue) => {
                          setgeofencechange(newValue)
                        }}

                      //   onChange={(event, newValue) => {
                      //     setRouteValue(newValue);
                      // }}
                      />
                    </Stack>
                    {/* <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={geofencelist}
                      onChange={handleChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.map((value) => {
                            var geofence = geofences.filter(function (item) {
                              return item.id == value
                            })
                            return (
                              <Chip key={geofence[0].name} label={geofence[0].name} />
                            )
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {geofences?.length > 0 ?
                        geofences?.map((geofence) => {
                          let startpoint = geofence.area.indexOf("(");
                          let geofencetype = geofence.area.substring(0, startpoint).trim();
                          geofencetype = geofencetype.toLowerCase();
                          return (
                            geofencetype === "circle" ?
                              <MenuItem
                                key={geofence.id}
                                value={geofence.id}
                                style={getStyles(geofence.name, geofence, theme)}
                              >
                                {geofence.name}
                              </MenuItem> : ""
                          )
                        }) : ""
                      }
                    </Select> */}

                  </FormControl>
                </Box>}
              <Box sx={{ marginTop: '1em' }}>
                <SelectedList
                  EditRouteId={EditRouteId}
                  legSubDetails={legSubDetails}
                  setlegSubDetails={setlegSubDetails}
                  setlegDetails={setlegDetails}
                  legDetails={legDetails}
                  handlePointsRemove={handlePointsRemove}
                  geofenceRemove={geofenceRemove}
                // setdeleteRoute = {setdeleteRoute}
                />
              </Box>

              <Box sx={{ marginTop: '1em' }}>
                <Button type="submit" className='show-btn'>
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Card>
        {/* <Box sx={{ width: "100%" }}> */}
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '73%',
              height: '73vh',
              marginBottom: '25px',
              marginLeft: '10px',
              '& a': {
                textDecoration: 'none',
                color: 'inherit',
              },
            }}
            className="first-card"
          >
            {/* <MapContainer center={[21.35516, 81.28199]} zoom={13} style={{ height: "200vh" }} zoomControl={false}>
               <TileLayer
                                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              /> 
              <TileLayer
                url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                maxZoom={20}
                subdomains={['mt1', 'mt2', 'mt3']}
              />

              <ZoomControl position="bottomleft" /> */}
              <CreateRouteMap legDetails={legDetails} />
            {/* </MapContainer> */}

            {/* <MapView  /> */}
          </Card>
        {/* </Box> */}
      </Box>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>

  );
}
