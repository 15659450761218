import React, { useState, useEffect } from "react";
import { useGetOne, useGetList, useListController, useNotify } from 'react-admin';
import Button from '@mui/material/Button';
import "../views/group/group.css";
import Modal from "@mui/material/Modal";
import WarningIcon from '@mui/icons-material/PriorityHighOutlined';
import DoneIcon from '@mui/icons-material/DoneOutlined';
import { Box, Typography } from '@mui/material';
import MobileIcon from '@mui/icons-material/SendToMobileOutlined';
import vtsDataProvider from './../providers/dataProvider';
import { getData } from './../providers/dataProvider';
import { ValidDeviceOtp } from './../providers/dataProvider';
import CloseIcon from '@mui/icons-material/HighlightOffOutlined';


const errorstyle = {
  color: "red",
  fontSize: "12px",
};

export const UnTagEntity = ({ untagopen, handleUntagClose, entityId,setUntagOpen }) => {
  const [otpResponse, setOtpResponse] = useState({});
  const [notifyData, setNotifyData] = useState({});
  const [ValidOTPdevice, setValidOTP] = useState({});
  const [validmessage, setValidMessage] = useState({})
  const [otpmessage, setOtpMessage] = useState({})
  const [disabled, setDisabled] = useState(true);
  const [otpInput, setOtpInput] = useState(new Array(6).fill(""));
  const notify = useNotify();

  const [verifyopen, setVerifyOpen] = useState(false);
  const [sucsopen, setSuccessOpen] = useState(false);
  const [untagdetails, setUnTagDetails] =useState(null);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(()=>{
    setOtpInput(new Array(6).fill(""))
    setValidMessage({})
    setValidOTP({})
},[untagopen])

  const handleVerifyOpen = () => {
    setUntagOpen(false);
    setVerifyOpen(true);
  };
  const handleVerifyClose = () => {
    setVerifyOpen(false);
  };
  
  const handleSuccessOpen = () => {
    setVerifyOpen(false);
    setSuccessOpen(true);
  };
  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };


  const { data: EntityData, isLoading, error } = useGetOne(
    'groups',
    { id: entityId },
    { enabled: (entityId > 0) }
  );



  useEffect(()=>{
    if(EntityData)
    {
    setUnTagDetails(EntityData)
    entityId = 0
    }
  },[EntityData])


  const sendTagOTP = async() =>
  {
    if (untagdetails) {
        const formValues1 = {
            deviceId: EntityData?.id
        }
        entityId = 0;

        const responsedata = await getData("untagotpsend", formValues1, setOtpResponse)
        setMinutes(1);
        setSeconds(30);
    }
   
   
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
  
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

//   useEffect(() => {
//     const getEntity = async () => {
//       if (EntityData) {
//         const formValues1 = {
//           deviceId: EntityData?.id,
//           permitHolderName: EntityData?.permit_holder,
//           clientPhoneNo: EntityData?.contact
//         }
//         entityId = 0;

//         const responsedata = await getData("otpsend", formValues1, setOtpResponse)
//       }
//     }
//     getEntity();
//   }, [EntityData]);


//   const resendotp = async() =>{
//     if (EntityData) {
//       const formValues1 = {
//         deviceId: EntityData?.id,
//         permitHolderName: EntityData?.permit_holder,
//         clientPhoneNo: EntityData?.contact
//       }
//      // entityId = 0;

//       const responsedata = await getData("otpsend", formValues1, setOtpResponse)
//     }

//   }


  useEffect(() => {
    if (otpResponse?.Error) {
      setOtpMessage({
        message:"Failed To send Otp"
      })
      handleVerifyOpen();
    }
    else if(otpResponse?.status)
    {
      setOtpMessage({
        message:"Please enter the OTP send to the verified mobile number"
      })
      handleVerifyOpen();
    }
    
  }, [otpResponse])

const untagModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    bgcolor: "background.paper",
    boxShadow: 1,
    p: 4,
    borderRadius: "8px"
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    bgcolor: "background.paper",
    boxShadow: 1,
    p: 4,
    borderRadius: "4px",
  };

  const handleChange = (e, index) => {
    
   
    //const inputs = document.getElementById("OTP");

  };

  const handleKeyDown = (e,index) => {

    if (e.key === 'Backspace' || e.key === 'Delete') {
      setOtpInput([
        ...otpInput?.map((d, idx) => (idx == index ? "" : d)),
      ]);

      switch (e.target.tabIndex) {
        case 1:
          document.getElementById("OTP1").focus();
          break;
        case 2:
          document.getElementById("OTP1").focus();
          break;
        case 3:
          document.getElementById("OTP2").focus();
          break;
          case 4:
          document.getElementById("OTP3").focus();
          break;
          case 5:
          document.getElementById("OTP4").focus();
          break;
          case 6:
            document.getElementById("OTP5").focus();
            break;
      }

    }
    else{
      if (isNaN(e.key)) return false;
      setOtpInput([
        ...otpInput?.map((d, idx) => (idx == index ? e.key : d)),
      ]);

    switch (e.target.tabIndex) {
      case 1:
        document.getElementById("OTP2").focus();
        break;
      case 2:
        document.getElementById("OTP3").focus();
        break;
      case 3:
        document.getElementById("OTP4").focus();
        break;
        case 4:
        document.getElementById("OTP5").focus();
        break;
        case 5:
        document.getElementById("OTP6").focus();
        break;
    }

    }
  };


  useEffect(() => {
    const otpFieldValue = otpInput.join("");
    otpFieldValue?.length == 6 ? setDisabled(false) : setDisabled(true);
  }, [otpInput]);

 
  const ValidOtp = (event) => {
    event.preventDefault();
    let paramvalues = {
        otp:otpInput.join(""),
        phone:untagdetails.contact,
        deviceId:untagdetails.id
    }
    //  const validOtpResponse = vtsDataProvider.update('otpsend', formValues, setNotifyData, " A OTP has been verified successfully", "Not Valid OTP");
    const validOtpResponse = ValidDeviceOtp('untagotpsend', paramvalues, setValidOTP)
  }

  useEffect(() => {
    if (ValidOTPdevice) {

      if(ValidOTPdevice?.Error)
      {
        setValidMessage({
          heading:"UnTag Failed",
          subheading: "Device untagging process has been Failed"
        })
        handleSuccessOpen();
      }
      else if (ValidOTPdevice?.status === "Invalid Otp.") {
        setValidMessage({
          heading:"UnTag Failed",
          subheading: ValidOTPdevice.status
        })
       // handleSuccessOpen();
      }
      else if(ValidOTPdevice?.status === "Valid Otp."){
        setValidMessage({
          heading:"Success",
          subheading: "Device untagging process has been initiated successfully"
        })
        handleSuccessOpen();
      }
      else if(ValidOTPdevice?.status !=="" && ValidOTPdevice?.status !== undefined && ValidOTPdevice?.status !== null){
        setValidMessage({
          heading:"UnTag Successful",
          subheading: ValidOTPdevice.status
        })
        handleSuccessOpen();
      }

    }
  }, [ValidOTPdevice])

  return (<>
   {/* Untag Modal */}

   <Modal
      open={untagopen}
      onClose={handleUntagClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description" className="AddNewModal untagModal"
    >
      <Box sx={untagModalstyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure
        </Typography>
        <span className="close-btn"><CloseIcon onClick={handleUntagClose} /></span>
         
              <Typography variant="p" component="p"> Are you sure want to initiate the untagging of the entity ? </Typography>
                <Box className="permit-detail">
                  <h2><span>Permit holder: </span> {untagdetails?.permit_holder}</h2>
                  <h2><span>Contact: </span> {untagdetails?.contact}</h2>
                  <h2><span>Chasis Number: </span> {untagdetails?.chasisno}</h2>
                  <h2><span>IMEI: </span> {untagdetails?.uniqueId}</h2>
                  <h2><span>Vehicle number: </span> {untagdetails?.name}</h2>
                </Box>
                <div style={{ marginTop: '2em' }} className="modal-footer">
                  <Button onClick={handleUntagClose} variant="contained" className="cancel-btn" color="primary" sx={{ marginRight: '1em' }}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit" className="create-btn" onClick={sendTagOTP}>
                  Send OTP
                  </Button>
                </div>
             
      </Box>
        </Modal>

        {/* verification modal */}

        <Modal
      open={verifyopen}
      onClose={handleVerifyClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="AddNewModal otp-verifyModal untagModal"
    >
      <form onSubmit={ValidOtp}>
        <Box sx={untagModalstyle} className="modal-box">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Verification
        </Typography>
        <span className="close-btn"><CloseIcon onClick={handleVerifyClose} /></span>
          <Box>
            <Typography variant="p" component="p">{otpmessage.message}  {untagdetails?.contact}</Typography>
            <div style={errorstyle}>
          {validmessage.subheading}
          </div>
            <div className="otp-verification">
              <form className="form-otp">
              {/* <Box id="OTP" className="otp-field"> */}
                  {otpInput?.map((data, index) => {
                    return (
                      <div className="form-group">
                      <input
                        type="text"
                        id={`OTP${index + 1}`}
                        className="form-control"
                        name='otp'
                        maxLength="1"
                          key={index}
                          tabIndex={index + 1}
                        value={data}
                        placeholder="——-"
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        required></input>
                    </div>
                    );
                  })}
                  {/* </Box> */}

              </form>
              <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="resendOTP" style={{textAlign:"left"}}>
                    {seconds > 0 || minutes > 0 ? (
                      <span className="pr-2"><span href="#" className="fc-435367">Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                        </span></span>
                    ) : (
                      <span className="pr-2"><span href="#" className="fc-435367">Didn’t receive the OTP?</span></span>
                    )}
                    
                      {/* <span className="pr-2"><span href="#" className="fc-435367">Didn’t receive the OTP?</span></span> */}
                      <a className="fc-818c99" onClick={(e) => sendTagOTP()} style={{float:"right",cursor:"pointer",marginRight:"5px"}}>Resend OTP</a>
                    </div>
                  </div>
                </div>
            </div>
              <div style={{ marginTop: '2em' }} className="modal-footer">
                  <Button onClick={handleVerifyClose} variant="contained" className="cancel-btn" color="primary" sx={{ marginRight: '1em' }}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit" 
                   className={disabled ? "create-btn" : ""}
                   disabled={disabled}
                   >
                  Verify
                  </Button>
              </div>
          </Box>

        </Box>
      </form>
    </Modal>

       {/* Otp successful */}
              {/* Otp Success modal */}
    <Modal
      open={sucsopen}
      onClose={handleSuccessClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="otp-successModal otp-suc-tag"
    >
      <Box sx={style} className="modal-box">
        <Typography id="modal-modal-title" variant="h6" component="h2">

        </Typography>

        <Box>
          <Typography variant="h6" component="h4">
           {validmessage.heading}
          </Typography>
          <Typography variant="p" component="p" sx={{ mt: 2 }}>
          {validmessage.subheading}
          </Typography>
          <div style={{ marginTop: "1em" }} className="modal-footer">
            <Button
              variant="contained"
              className="continue-btn"
              onClick={handleSuccessClose}
              color="primary"
              sx={{ marginRight: "1em" }}
            >
              Okay
            </Button>
          </div>
        </Box>

      </Box>
    </Modal>
  </>

  );

}


