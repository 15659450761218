import React, { useEffect, useContext, useState } from 'react';
import "../../../src/views/layout/mapview.css";
import { initialize, MMI_KEY } from '../../utils/initializeMap';
import { getMarkerPopup, mapIcon } from '../../utils/mapContext';
import icon from '../../../src/images/GTruck.png';
import urls from '../../constants';
import { useLocation } from "react-router-dom";
import usePosition from '../../hooks/usePosition';

let DefaultIcon = icon
const Vehiclemapshare = ({value}) => {
    const location = useLocation()
    const [positions, setPositions, setSocketUrl] = usePosition();
    let wsUrl = urls.WEBSOCKET_URL.replace(/http/, 'ws');
    const [movemarker, setmovemarker] = useState([]);
    const [movingmarker, setmovingmarker] = useState(null);
    const [previous_latlng, setprevious_latlng] = useState("");
    const [markers, setMarkers] = useState(null);
    const [layerstyle, setLayerStyle] = useState("standard-day");
    const [mapObject, setmapObject] = useState(null)
    const [vehicleData, setVehicleData] = useState(null);

    // const queryParams = new URLSearchParams(location.search);
    // const value = queryParams.get('id');
    const styleMap = { width: '99%', height: '69vh', display: 'inline-block' }
    var mapObj;
    let newMarkers = [];
    let token = localStorage.getItem("authToken");

    useEffect(()=>{
        login();
    },[])

    const login = async() =>{
        var details = {
            email: "manthaappu@cgvts.cgstate.gov.in",
            password: "Trackolet@123#",
          };
          var formBody = [];

        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");
      
          const request = new Request(urls.SESSION_URL, {
            method: "POST",
            body: formBody,
            headers: new Headers({
              "Content-Type": "application/x-www-form-urlencoded",
            }),
          });
          return fetch(request, { credentials: "include" })
            .then((response) => response.json().then(data => {
              if(data?.errMessage == undefined) {
                return data
              }
              if (data?.errMessage == 'Your account is disabled') {
                throw new Error(data?.errMessage);
              }
              if(data?.errMessage == 'You have entered an invalid username or password'){
                throw new Error(data?.errMessage);
              }
              if (data?.errMessage == 'Your account have been expired') {
                throw new Error(data?.errMessage);
              }
            }))
            .then((auth) => {
              localStorage.setItem("authToken", auth.token);
              localStorage.setItem("auth", JSON.stringify(auth));
            })
    }
    
    useEffect(() => {
        const GetVehicleDetail = () => {
            fetch(`https://cgvtsapi.cgstate.gov.in/api/devices/search?deviceId=${(value)}`, {
                headers: {
                    'Authorization': 'Token ' + token,
                    'content-type': 'application/json'
                }
            })
                .then((e) => e.json())
                .then((json) => setVehicleData(json?.data[0]))
                .catch((errors) => console.log("errors", errors))
        }
        GetVehicleDetail();
    }, [value,token])

    useEffect(() => {
        setmapObject(null);
        initialize(MMI_KEY, () => {
            afterScriptsLoaded();
        })
    }, [])

    useEffect(() => {
        if (vehicleData) {
            newMarkers = {
                lat: vehicleData?.position?.latitude,
                long: vehicleData?.position?.longitude,
                id: vehicleData?.device?.id,
                type: vehicleData?.position?.type,
                speed: vehicleData?.position?.speed ? vehicleData?.position?.speed?.toFixed(2) : 0,
                highlight: true,
                status: vehicleData?.device?.status,
                course: vehicleData?.position?.course,
                vehicle_no: vehicleData?.device?.uniqueidno ? vehicleData?.device?.uniqueidno : 0,
                chasis_no: vehicleData?.device?.chasisno ? vehicleData?.device?.chasisno : '',
                imei_no: vehicleData?.device?.imei ? vehicleData?.device?.imei : '',
                permit_holder: vehicleData?.device?.permit_holder ? vehicleData?.device?.permit_holder : '',
                contact: vehicleData?.device?.contact ? vehicleData?.device?.contact : 0,
                rto: vehicleData?.device?.rto_code ? vehicleData?.device?.rto_code : 0,
                company: vehicleData?.device?.make ? vehicleData?.device?.make : '',
                name: vehicleData?.device?.name ? vehicleData?.device?.name : vehicleData?.device?.vehicleNo,
                time: vehicleData?.device?.eventTime,
                address: vehicleData?.device?.address
            };
            setMarkers(newMarkers);
        }
        else setMarkers(null);
    }, [vehicleData]);

    useEffect(() => {
        if (mapObject) {
            loadDataOnlyOnce();
            clickLayer();
        }
    }, [mapObject])

    function afterScriptsLoaded() {
        mapObj = window.mappls.Map('map', {
            center: [21.35516, 81.28199],
            zoomControl: true,
            zoom: 8,
            location: true,
            hybrid: true,
            search: true
        })
        window.mappls.setStyle(layerstyle)

        setmapObject(mapObj)
    }

    useEffect(() => {
        if(mapObject)
        {
           setmapObject(null);
           afterScriptsLoaded();
          window.mappls.setStyle(layerstyle)
        }
      }, [layerstyle])

    function clickLayer() {
        // if(mapObject){
        document.getElementById('map_default').onclick = function (e) {
            setLayers('standard-day')
        }
        document.getElementById('grey-day').onclick = function (e) {
            setLayers('grey-day')
        }
        document.getElementById('standard-night').onclick = function (e) {
            setLayers('standard-night')
        }
        document.getElementById('map_hybrid').onclick = function (e) {
            setLayers('standard-hybrid')
        }
    }

    const loadDataOnlyOnce = () => {
        var container = document.querySelector(".expand-map-control")
        var layer_id = document.getElementById('layerId')
        if (!layer_id)
            container.innerHTML += '<li id="myBtn" class="map-layers"><a style="height: 36px;line-height: 32px;" title=" Map Layers" nofollow="" > <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LayersIcon"><path d="m11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"></path></svg></a>\
                            <ul class="clearfix map-layer" id="map-layer" style="display:none">\
                            <span class="" id="layer-close"> X </span>\
                            <li id="map_default" class="dyLayerMap">\
                            <a href="javascript:void(0)" class="mapStyleBlock active">\
                            <span class="layer-item-img">\
                            <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" />\
                            <img src="https://apis.mapmyindia.com/vector_map/thumb/style.jpg" alt="" class="mode-img" />\
                            </span>\
                            <p>Default Map </p>\
                            </a>\
                            </li><li id="grey-day" class="dyLayerMap">\
                            <a href="javascript:void(0)" class="mapStyleBlock">\
                            <span class="layer-item-img">\
                            <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                            <img src="https://www.mappls.com/images/gray.jpg" alt="" class="mode-img" style={{width: "82px",height: "56px",right: 0,bottom: 0}} />\
                            </span>\
                            <p>Grey Mode </p>\
                            </a>\
                            </li><li id="standard-night" class="dyLayerMap">\
                            <a href="javascript:void(0)" class="mapStyleBlock" >\
                            <span class="layer-item-img">\
                            <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                            <img src="https://www.mappls.com/images/nightmode.jpg" alt="" class="mode-img" />\
                            </span>\
                            <p>Night Mode </p>\
                            </a></li>\
                            <li id="map_hybrid" class="dyLayerMap">\
                            <a href="javascript:void(0)" class="mapStyleBlock" >\
                            <span class="layer-item-img">\
                            <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                            <img src="https://www.mappls.com/images/maplayer/hy_map.png" alt="" class="mode-img" />\
                            </span>\
                            <p>Satellite </p>\
                            </a></li>\
                            </ul>\
                            </li>'
    };

    const setLayers = (id) => {
        setLayerStyle(id)
    }

    useEffect(() => {
        if (mapObject && markers) {
            fHighlightMarker();
        }
    }, [mapObject, markers,positions])

    function angleFromCoordinate(lat1, lon1, lat2, lon2) {
        var p1 = { x: lat1, y: lon1 };
        var p2 = { x: lat2, y: lon2 };
        // angle in radians
        var angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
        // angle in degrees
        var angleDeg = Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;
        return angleDeg;
      }

    const fHighlightMarker = () => {
        if (mapObject) {
            if (markers) {
                let center = null;

                let pos = [markers.lat, markers.long];
                var hed;
                DefaultIcon = mapIcon(markers)
                if (markers.highlight === true) {
                    setSocketUrl(wsUrl.concat('?deviceId=' + markers?.id));

                    positions?.map((element, index) => {
                    if (element.deviceId === markers.id) {
                        if (previous_latlng?.lat === "" || previous_latlng?.lat === undefined) {
                        setprevious_latlng({ lat: pos[0], lng: pos[1] });
                        }

                        hed = angleFromCoordinate(previous_latlng.lat, previous_latlng.lng, element.latitude, element.longitude);
                        setprevious_latlng({ lat: element.latitude, lng: element.longitude });
                        pos = [element.latitude, element.longitude];
                        markers.course = element.course;
                    }
                    })

                    if (movingmarker) window.mappls.remove({ map: mapObject, layer: movingmarker });
                    var marker1 = window.mappls.Marker({
                        map: mapObject,
                        position: { lat: pos[0], lng: pos[1] },
                        html: "<img class='moving-icon' id='movingmarkerid" + markers.id + "'  style='transform: rotate(" + hed + "deg)'   src='" + DefaultIcon + "'/>",
                        fitbounds: true,
                        popupHtml: getMarkerPopup(markers),
                    });
                    setmovingmarker(marker1);
                    center = { lat: pos[0], lng: pos[1] }
                }

                if (center) {
                    mapObject.setCenter({ lat: center.lat, lng: center.lng });
                }
            }
            return (() => {
            });
        }
    }

    return (
        <div id="map" style={styleMap}>
        </div>
    );
}

export default Vehiclemapshare;
